import React from 'react';
import { UsernameDialogProps } from './AuthDialog.types';
import UsernameForm from 'views/forms/UsernameForm/UsernameForm';

export const UsernameDialog = ({
  onClose,
  onSignUpCompleted,
  onCompleteRedirectPage,
  rewardSoftCurrency,
}: UsernameDialogProps) => {
  return (
    <div className="px-7 pt-14 landscape:max-mediumLarge:pt-0">
      <UsernameForm
        onClose={onClose}
        onSignUpCompleted={onSignUpCompleted}
        onCompleteRedirectPage={onCompleteRedirectPage}
        rewardSoftCurrency={rewardSoftCurrency}
        formOrigin={'username'}
      />
    </div>
  );
};
