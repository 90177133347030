import { twc } from 'react-twc';

const sharedStyles = `font-bold break-words m-0`;

export const Heading1 = twc.h1(() => [sharedStyles, `text-h1`]);
export const Heading2 = twc.h2(() => [sharedStyles, `text-h2`]);
export const Heading3 = twc.h3(() => [sharedStyles, `text-h3`]);
export const Heading4 = twc.h4(() => [sharedStyles, `text-h4`]);
export const Heading5 = twc.h5(() => [sharedStyles, `text-h5`]);
export const Heading6 = twc.h6(() => [sharedStyles, `text-h6`]);
