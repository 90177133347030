import { AddUser, Chat, Like, Platformer, Trophy } from '@hiberworld/icons';
import { GamekitIcon } from 'components/legacy/GamekitIcon';
import { GameKit, NotificationsTypes } from 'generated/graphql';
import { limitString } from 'lib/limitString';
import { ReactNode } from 'react';
import theme from 'style/theme';
import { NotificationEdge } from './Notification.types';

export const notificationsTypesMap = {
  [NotificationsTypes.Followed]: 0,
  [NotificationsTypes.FriendRequest]: 1,
  [NotificationsTypes.Liked]: 2,
  [NotificationsTypes.Comment]: 3,
  [NotificationsTypes.Mentioned]: 4,
  [NotificationsTypes.NewContent]: 5,
  [NotificationsTypes.Announcement]: 6,
  [NotificationsTypes.FriendRequestAccepted]: 7,
  [NotificationsTypes.HighscoreBeaten]: 8,
  [NotificationsTypes.FeaturedContent]: 9,
  [NotificationsTypes.ScoreBeaten]: 10,
  [NotificationsTypes.KnockedOutTop_10]: 11,
};

export const getIcon = (type: number, gameKit?: GameKit | null): ReactNode => {
  switch (Object.keys(notificationsTypesMap)[type]) {
    case NotificationsTypes.Followed:
    case NotificationsTypes.FriendRequest:
    case NotificationsTypes.FriendRequestAccepted:
      return <AddUser color={theme.colors.notification.icon.addUser} />;

    case NotificationsTypes.Liked:
      return <Like color={theme.colors.notification.icon.liked} />;

    case NotificationsTypes.Comment:
    case NotificationsTypes.Mentioned:
      return <Chat color={theme.colors.text.default} />;

    case NotificationsTypes.HighscoreBeaten:
    case NotificationsTypes.ScoreBeaten:
    case NotificationsTypes.KnockedOutTop_10:
    case NotificationsTypes.FeaturedContent:
      return <Trophy color={theme.colors.text.default} />;

    case NotificationsTypes.NewContent:
    case NotificationsTypes.Announcement:
    default:
      return gameKit ? (
        <GamekitIcon color={theme.colors.notification.icon.default} size={14} gamekit={gameKit} />
      ) : (
        <Platformer color={theme.colors.notification.icon.default} />
      );
  }
};

const beginningText = (total: number, username: string, type: number) => {
  if (
    Object.keys(notificationsTypesMap)[type] === NotificationsTypes.NewContent ||
    Object.keys(notificationsTypesMap)[type] === NotificationsTypes.FeaturedContent
  ) {
    return '';
  }

  if (total <= 1) {
    return `<span>${username}</span>`;
  }
  if (total >= 2) {
    return `<span>${username}</span> and <span>${total > 99 ? '99+' : total - 1}</span> ${
      total > 2 ? 'others' : 'other'
    }`;
  }
};
const truncateString = (str: string, limit = 10) => {
  return str ? `"<span>${limitString({ text: str, limit })}</span>"` : '';
};

export const getNotificationText = (notification: NotificationEdge) => {
  const {
    total,
    lastUsers: [lastUser],
    type,
    sourceContentType,
    content,
  } = notification;

  const sourceIsProject = sourceContentType === 0;

  const { customData } = content;

  const projectTitle = truncateString(customData?.projectTitle ?? '', 256);
  const commentText = truncateString(customData?.commentText ?? '', 256);

  const sourceTypeName = ['World', 'comment'][sourceContentType ?? 0];

  let endingText = '';

  switch (Object.keys(notificationsTypesMap)[type]) {
    case NotificationsTypes.Followed:
      endingText = 'started following you';
      break;

    case NotificationsTypes.FriendRequest:
      endingText = `sent you a friend request`;
      break;

    case NotificationsTypes.Liked:
      endingText = `liked your ${sourceTypeName}: ${sourceIsProject ? projectTitle : commentText}`;
      break;

    case NotificationsTypes.Comment:
      endingText =
        sourceContentType === 0
          ? `commented: ${total === 0 ? commentText : ''} on your ${sourceTypeName}: ${projectTitle}`
          : `replied to your comment: ${truncateString(customData?.commentText ?? '', 24)}`;
      break;

    case NotificationsTypes.Mentioned:
      endingText = `mentioned you in a ${sourceTypeName}: ${sourceIsProject ? projectTitle : commentText}`;
      break;

    case NotificationsTypes.NewContent:
      return `New ${sourceTypeName}: ${projectTitle} by <span>${lastUser.username}</span>`;
      break;

    case NotificationsTypes.Announcement:
      endingText = '';
      break;

    case NotificationsTypes.FriendRequestAccepted:
      endingText = `accepted your friend request`;
      break;

    case NotificationsTypes.HighscoreBeaten:
      endingText = `demolished your record on ${projectTitle}`;
      break;

    case NotificationsTypes.FeaturedContent:
      return `Your ${sourceTypeName} ${projectTitle} is now featured, keep it up!`;

    case NotificationsTypes.ScoreBeaten:
      if (total <= 1) {
        endingText = `beat your record on ${projectTitle}`;
      } else if (total >= 2) {
        endingText = `friend${total === 2 ? '' : 's'} beat your record on ${projectTitle}`;
      }
      break;

    case NotificationsTypes.KnockedOutTop_10:
      endingText = `knocked you out of top 10 on ${projectTitle}`;
      break;

    default:
      endingText = '';
      break;
  }

  return `${beginningText(total, lastUser.username, type)} ${endingText}`;
};
