export const isTouch = () => {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        (window.DocumentTouch && typeof document !== 'undefined' && document instanceof window.DocumentTouch))
    ) ||
    // eslint-disable-next-line @typescript-eslint/dot-notation
    !!(typeof navigator !== 'undefined' && (navigator.maxTouchPoints || navigator['msMaxTouchPoints'])) ||
    (typeof window !== 'undefined' && window.matchMedia('(any-hover: none)').matches === true)
  );
};
