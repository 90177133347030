import { GraphQLError } from 'graphql';

export interface FieldErrorResponse {
  id: string;
  defaultMessage: string;
}

export interface GraphQLErrorWithFields extends GraphQLError {
  fields?: FieldErrorResponse[];
}

export type ErrorResponse = readonly GraphQLErrorWithFields[];

export const getFieldValidationErrors = (errors?: ErrorResponse | GraphQLError): string => {
  if (!errors) {
    return '';
  }

  const fields = errors[0]?.fields || errors[0]?.extensions?.fields;

  return fields
    ? Object.values(fields)
        .flat()
        .filter(({ id }) => id !== 'validation.error')
        .map((error: FieldErrorResponse) => error.defaultMessage)
        .join(', ')
    : errors[0].message;
};

export const getGraphqlFieldErrors = (errors?: ErrorResponse | GraphQLError) => {
  if (!errors) {
    return '';
  }

  if ('message' in errors) {
    return errors.message;
  }
  return getFieldValidationErrors(errors);
};
