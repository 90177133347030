import cookie, { CookieParseOptions } from 'cookie';
import { GetServerSidePropsContext, NextPageContext } from 'next';

export type Context = NextPageContext | GetServerSidePropsContext;

export const parseCookies = (context?: Context, options?: CookieParseOptions) => {
  return cookie.parse(
    context?.req ? context?.req?.headers?.cookie || '' : (typeof window !== 'undefined' && document.cookie) || '',
    options
  );
};
