import { QueryHookOptions } from '@apollo/client';
import { useNotificationsQuery } from 'generated/graphql';

export const useNotifications = (options: QueryHookOptions = {}) => {
  const { data, loading, error, fetchMore, refetch } = useNotificationsQuery({
    notifyOnNetworkStatusChange: true,
    ...options,
  });
  const cursor = data?.me?.notifications?.pageInfo?.endCursor;

  return {
    fetchMore: () => fetchMore({ variables: { cursor } }),
    notifications: data?.me?.notifications,
    loading,
    error,
    refetch,
  };
};
