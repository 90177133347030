import { Platformer, Playground, Shooter } from '@hiberworld/icons';
import { GameKit } from 'generated/graphql';
import { GamekitProps } from './GamekitIcon.types';

export const GamekitIcon = ({ gamekit, ...props }: GamekitProps) => {
  if (gamekit === GameKit.Platformer) {
    return <Platformer title="gamekit platformer" {...props} />;
  }
  if (gamekit === GameKit.Shooter) {
    return <Shooter title="gamekit shooter" {...props} />;
  }
  if (gamekit === GameKit.Playground) {
    return <Playground title="gamekit playground" {...props} />;
  }
  return null;
};
