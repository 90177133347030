import React from 'react';
import * as S from './Skeleton.styles';
import { SkeletonLoaderProps } from './Skeleton.types';

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ children, maxWidth }) => {
  return (
    <S.Row maxWidth={maxWidth} data-testid="loading...">
      {children}
    </S.Row>
  );
};
