export type PreviousPageData = {
  url: string;
  timeStamp: number;
};

export enum DiveUserType {
  guestUser = 0,
  nonRegisteredUser = 1,
  registeredUser = 2,
}
