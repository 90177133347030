import { Heading5, Heading6 } from 'components/typography';
import { useEffectOnce } from 'lib/useOnMount';
import { useEffect, useRef, useState } from 'react';

export const SoftCurrencyDisplay = ({ currency }: { currency: number | undefined | null }) => {
  const [smallScreen, setSmallScreen] = useState(false);
  const previousCurrencyRef = useRef<number>(currency || 0);
  const readyRef = useRef<boolean>(false);
  const isIntAnimationSupportedRef = useRef<boolean>(false);

  useEffectOnce(() => {
    checkScreenSize();
    addEventListener('resize', checkScreenSize);

    return () => removeEventListener('resize', checkScreenSize);
  });

  // Update the animation keyframes when the currency changes
  useEffect(() => {
    const styleId = 'currency-animation-style';
    let styleSheet = document.getElementById(styleId) as HTMLStyleElement | null;

    // Check if style has already been added
    if (!styleSheet) {
      styleSheet = document.createElement('style');
      styleSheet.id = styleId;
      document.head.appendChild(styleSheet);
    }

    // The keyframes need to be added here so I add the --num-hibux property as well, which is what
    // the keyframe are animating
    styleSheet.innerHTML = `
      @keyframes counter {
        from {
          --num-hibux: ${previousCurrencyRef.current || 0};
        }
        to {
          --num-hibux: ${currency || 0};
        }
      }
    `;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    isIntAnimationSupportedRef.current = !isSafari;

    previousCurrencyRef.current = currency; // Update the previous currency value

    if (!readyRef.current) {
      readyRef.current = true;
    }
  }, [currency]);

  const checkScreenSize = () => {
    if (window.innerWidth <= 400) {
      setSmallScreen(true);
      return;
    }
    setSmallScreen(false);
  };

  if (!isIntAnimationSupportedRef.current || !readyRef.current) {
    if (smallScreen && currency && currency >= 100_000) {
      return <Heading6>{currency}</Heading6>;
    }

    return <Heading5>{currency}</Heading5>;
  }

  if (smallScreen && currency && currency >= 100_000) {
    return (
      <Heading6>
        <span
          key={currency}
          className="animate-[counter_1s_ease-out_forwards] tabular-nums [counter-set:_num_var(--num-hibux)] before:content-[counter(num)] before:left-[calc(0.4em * var(--n, 1))]"
        />
      </Heading6>
    );
  }

  return (
    <Heading5>
      <span
        key={currency}
        className="animate-[counter_1s_ease-out_forwards] tabular-nums [counter-set:_num_var(--num-hibux)] before:content-[counter(num)] before:left-[calc(0.4em * var(--n, 1))]">
        <span></span>
      </span>
    </Heading5>
  );
};
