import { Paragraph } from './Paragraph';
import { WarningTriangle } from '@hiberworld/icons';

type ErrorMessageProps = {
  message?: string | JSX.Element;
  contrastColor?: boolean;
  className?: string;
};

export const ErrorMessage = ({ message, contrastColor, className }: ErrorMessageProps) => {
  if (!message) {
    return null;
  }

  const textColor = contrastColor ? 'text-greyscale-veryLightGrey' : 'text-text-error';

  return (
    <div className={`flex gap-[0.3rem] ${className}`}>
      <WarningTriangle size="1.4rem" title="Error" className={`${textColor} shrink-0`} />
      <Paragraph size="small" role="alert" className={textColor}>
        {message}
      </Paragraph>
    </div>
  );
};
