import { Arrow, Close } from '@hiberworld/icons';
import { Heading3 } from 'components/typography';
import Theme from 'style/theme';
import styled from 'styled-components';
import { ModalButtonProps, ModalStyleProps } from './Modal.types';
import { twc } from 'react-twc';

export const ModalContainer = styled.div<ModalStyleProps>`
  padding: ${({ padding }) => padding ?? '44px 56px'};
  min-width: 400px;
  max-width: ${({ maxWidth }) => maxWidth ?? '452px'};
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  z-index: 99999;
  background-color: ${({ theme }) => theme.primaryColor.dark};
  border-radius: 0.5rem;
  pointer-events: painted;
  @media (max-width: ${({ theme }) => theme.breakpoint.small}) {
    padding: 44px 20px 16px 20px;
    min-width: unset;
  }
  label {
    display: block;
  }
`;

export const Headline = styled(Heading3)`
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.primaryColor.white};
  @media (max-width: ${({ theme }) => theme.breakpoint.small}) {
    padding: 0 40px;
  }
`;

export const HiberFigure = styled.img<{ top?: string; width?: string }>`
  position: absolute;
  left: calc(50% - 20px);
  top: ${({ top }) => top ?? '-92px'};
  width: ${({ width }) => width ?? '204px'};
`;

export const RpmFrontLight = styled.img<{ top?: string; width?: string }>`
  position: absolute;
  left: calc(50% - 20px);
  top: ${({ top }) => top ?? '-132.5px'};
  width: ${({ width }) => width ?? '204px'};
`;

export const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.primaryColor.appLightGray};
  flex: 1;
`;

export const Link = styled.a<{
  fontSize?: string;
  bold?: boolean;
  underline?: boolean;
  color: keyof (typeof Theme)['primaryColor'];
}>`
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  color: ${({ theme, color }) => theme.primaryColor[color]};
  margin: 0;
  ${({ bold }) => bold && 'font-weight: bold;'}
  &:hover {
    color: ${({ theme }) => theme.primaryColor.red};
    cursor: pointer;
  }
`;

export const Text = styled.p<{
  fontSize?: string;
  lineHeight?: string;
  textAlign?: string;
  color?: string;
  margin?: string;
}>`
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  line-height: ${({ lineHeight }) => lineHeight ?? '24px'};
  color: ${({ theme, color }) => color ?? theme.primaryColor.veryLightGray};
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  margin: ${({ margin }) => margin ?? 0};
`;

const BackButtonContainer = styled.div<{ inset?: string }>`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  backdrop-filter: blur(8px);
  position: absolute;
  inset: ${({ inset }) => inset ?? '20px auto auto 20px'};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const CloseButtonContainer = styled(BackButtonContainer)<{ $inset?: string }>`
  z-index: 19099999;
  inset: ${({ $inset }) => $inset ?? '20px 20px auto auto'};
`;

export const BackButton = ({ onClick, inset }: ModalButtonProps) => (
  <BackButtonContainer onClick={onClick} inset={inset} role="button">
    <Arrow size={24} className="text-greyscale-white" />
  </BackButtonContainer>
);

export const CloseButton = ({ onClick, inset }: ModalButtonProps) => (
  <CloseButtonContainer onClick={onClick} $inset={inset} role="button">
    <Close size={24} className="text-greyscale-white" />
  </CloseButtonContainer>
);

export const Thumbnail = twc.img`h-auto w-[124px] rounded-lg`;

export const OverflowWrapper = twc.div`overflow-auto py-0.5 max-h-[90vh]`;
