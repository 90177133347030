type Env = 'prod' | 'stage' | 'dev' | 'test1';

export const prettyEnv = (env: string): Env => {
  const envs = {
    production: 'prod',
    prod: 'prod',
    staging: 'stage',
    stage: 'stage',
    development: 'dev',
    dev: 'dev',
    test1: 'test1',
  };

  return envs[env] || 'prod';
};
