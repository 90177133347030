import { TwcComponentProps, twc } from 'react-twc';

type ButtonTextTwcProps = TwcComponentProps<'span'> & {
  size: 'small' | 'medium' | 'large';
};

export const ButtonText = twc.span.transientProps(['size'])<ButtonTextTwcProps>(({ size }) => [
  `font-semibold m-0`,
  size === 'small' && 'text-button_small',
  size === 'medium' && 'text-button_medium',
  size === 'large' && 'text-button_large',
]);
