import styled, { css, keyframes } from 'styled-components';

const centerStyle = css`
  display: flex;
  justify-content: center;
  margin: auto;
`;

const tinyStyle = css`
  width: 1rem;
  height: 1rem;
`;

const miniStyle = css`
  width: 1.5rem;
  height: 1.5rem;
`;

const smallStyle = css`
  width: 2rem;
  height: 2rem;
`;

const mediumStyle = css`
  width: 6rem;
  height: 6rem;
`;

const bigStyle = css`
  width: 14rem;
  height: 14rem;
`;

const noMarginStyle = css`
  margin-top: 0;
  margin-bottom: 0;
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const varyingOffset = keyframes`
  0% {
    stroke-dashoffset: 260;
  }
  50% {
    stroke-dashoffset: 210;
  }
  100% {
    stroke-dashoffset: 260;
  }
`;

export type SpinnerProps = {
  small?: boolean;
  tiny?: boolean;
  red?: boolean;
  white?: boolean;
  big?: boolean;
  center?: boolean;
  noMargin?: boolean;
  className?: string;
  medium?: boolean;
  mini?: boolean;
};

export const SpinnerSvg = styled.svg<SpinnerProps>`
  animation: ${varyingOffset} 1.2s 0s ease-out infinite reverse, ${spin} 1s 0.2s linear infinite;
  ${({ small }) => small && smallStyle};
  ${({ tiny }) => tiny && tinyStyle};
  ${({ medium }) => medium && mediumStyle};
  ${({ big }) => big && bigStyle};
  ${({ center }) => center && centerStyle};
  ${({ noMargin }) => noMargin && noMarginStyle};
  ${({ mini }) => mini && miniStyle};
`;

const grow = keyframes`
0% {
  display:none;
  height: 0vw;
  width: 0vw;
  display: block;
}
10% {
  height: 0vw;
  width: 0vw;
}
45% {
  height: 10vw;
  width: 10vw;
}
65% {
  height: 10vw;
  width: 10vw;
  opacity: 1;
}
100% {
  height: 16vw;
  width: 16vw;
  opacity: 0;
}
`;

export const GrowAnimation = styled.div`
  position: relative;
  margin-bottom: 8px;
  margin-left: 5px;
  height: 1vw;
  width: 1vw;
  animation: 1.2s ${grow} ease-out forwards;
`;

export const fadeIn = keyframes`to {
  opacity: 1;
}`;

export const GradientBackground = styled.div`
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: 0;
  background: radial-gradient(168.36% 100% at 50.18% 0%, rgba(52, 53, 64, 0.8) 0%, rgba(27, 28, 32, 0.8) 100%), #1b1c20;
  animation: ${fadeIn} 0.4s linear forwards;
  display: flex;
  align-items: center;
  justify-content: center;
`;
