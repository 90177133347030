import { config } from 'config';
import {
  CurrentUserDocument,
  CurrentUserFragmentDoc,
  CurrentUserQuery,
  useGenerateUploadPictureUrlMutation,
  UserByUsernameDocument,
  UserByUsernameQuery,
  UserByUsernameQueryVariables,
  useRemoveProfileBackgroundMutation,
  useRemoveProfilePictureMutation,
  useUpdateAchievementPublicityMutation,
  useUpdateBirthdateMutation,
  useUpdateDescriptionMutation,
  useUpdateDirectMessagingPublicityMutation,
  useUpdateEmailMutation,
  useUpdateFavoriteGamesPublicityMutation,
  useUpdateFriendsPublicityMutation,
  useUpdateOnlyFriendsInMultiplayerMutation,
  useUpdatePasswordMutation,
  useUpdateProfileBackgroundMutation,
  useUpdateSocialMediaMutation,
  useUpdateUserTitleMutation,
} from 'generated/graphql';
import { useMe } from 'hooks/useMe';

export const useUpdateProfile = () => {
  const { me } = useMe();
  const [updateEmail, emaildata] = useUpdateEmailMutation({ notifyOnNetworkStatusChange: true });
  const [updatePassword, passworddata] = useUpdatePasswordMutation({ notifyOnNetworkStatusChange: true });
  const [updateBirthdate, birthdatedata] = useUpdateBirthdateMutation({ notifyOnNetworkStatusChange: true });
  const [updateSocialMedia, socialMediadata] = useUpdateSocialMediaMutation({ notifyOnNetworkStatusChange: true });
  const [updateFriendsPublicity, friendsPublicitydata] = useUpdateFriendsPublicityMutation({
    notifyOnNetworkStatusChange: true,
  });
  const [updateFriendsInMultiplayer, friendsInMultiplayerdata] = useUpdateOnlyFriendsInMultiplayerMutation({
    notifyOnNetworkStatusChange: true,
  });
  const [updateDescription, descriptiondata] = useUpdateDescriptionMutation({ notifyOnNetworkStatusChange: true });

  const [updateUserTitle, userTitledata] = useUpdateUserTitleMutation({ notifyOnNetworkStatusChange: true });

  const [updateAchievementPublicity, achievementPublicitydata] = useUpdateAchievementPublicityMutation({
    notifyOnNetworkStatusChange: true,
  });

  const [updateFavoriteGamesPublicity] = useUpdateFavoriteGamesPublicityMutation({
    notifyOnNetworkStatusChange: true,
  });

  const [updateDirectMessagingPublicity, directMessagingPublicitydata] = useUpdateDirectMessagingPublicityMutation({
    notifyOnNetworkStatusChange: true,
  });
  const [updateOnlyFriendsInMultiplayer] = useUpdateOnlyFriendsInMultiplayerMutation({
    notifyOnNetworkStatusChange: true,
  });

  const [updateProfileBackground] = useUpdateProfileBackgroundMutation({
    notifyOnNetworkStatusChange: true,
  });
  const [removeProfileBackground] = useRemoveProfileBackgroundMutation({
    notifyOnNetworkStatusChange: true,
    optimisticResponse: {
      __typename: 'Mutation',
      removeProfileBackground: {
        id: '-1',
        profileBackgroundUrl: `${config.cdn}/static/images/homeworld_default_dark.png`,
      },
    },
    update: (store, result) => {
      if (!me) {
        return;
      }

      const id = store.identify(me);

      if (result.data?.removeProfileBackground?.profileBackgroundUrl) {
        store.writeFragment({
          id,
          fragment: CurrentUserFragmentDoc,
          data: {
            ...me,
            profileBackgroundUrl: result.data?.removeProfileBackground?.profileBackgroundUrl,
          },
        });

        return;
      }

      const data = store.readQuery<UserByUsernameQuery, UserByUsernameQueryVariables>({
        query: UserByUsernameDocument,
        variables: { username: me?.username },
      });

      let profileBackgroundUrl = `${config.cdn}/static/images/homeworld_default_dark.png`;

      if (
        data?.userByUsername?.homeworld?.project.isPublished &&
        data.userByUsername.homeworld.project.thumbnail?.large.url
      ) {
        profileBackgroundUrl = data.userByUsername.homeworld.project.thumbnail?.large.url;
      }

      me.profileBackgroundUrl = profileBackgroundUrl;
      store.writeQuery({ query: CurrentUserDocument, data: { me } });
    },
  });
  const [removeProfilePicture] = useRemoveProfilePictureMutation({
    notifyOnNetworkStatusChange: true,
    optimisticResponse: {
      __typename: 'Mutation',
      removeProfilePicture: true,
    },
    update: store => {
      const data = store.readQuery<CurrentUserQuery>({ query: CurrentUserDocument });

      if (data?.me) {
        data.me.hasDefaultProfileImage = true;
        data.me.pictureUrl = 'https://cdn.hibervr.com/static/images/profile-images/gray.png';
      }

      store.writeQuery({ query: CurrentUserDocument, data });
    },
  });

  const [generateUploadPictureUrl] = useGenerateUploadPictureUrlMutation({ notifyOnNetworkStatusChange: true });

  const loading = [
    emaildata,
    passworddata,
    birthdatedata,
    socialMediadata,
    friendsPublicitydata,
    friendsInMultiplayerdata,
    descriptiondata,
    userTitledata,
    achievementPublicitydata,
    directMessagingPublicitydata,
  ].some(data => data.loading);

  return {
    updateEmail,
    updatePassword,
    updateSocialMedia,
    updateBirthdate,
    updateFriendsPublicity,
    updateFriendsInMultiplayer,
    updateProfileBackground,
    updateDescription,
    removeProfilePicture,
    removeProfileBackground,
    updateOnlyFriendsInMultiplayer,
    updateAchievementPublicity,
    updateFavoriteGamesPublicity,
    updateDirectMessagingPublicity,
    generateUploadPictureUrl,
    updateUserTitle,
    loading,
  };
};
