import { Bell } from '@hiberworld/icons';
import { CirclePlaceholder, LoadingCard } from 'components/common/SkeletonLoader';
import { Button } from 'components/common/buttons/Button';
import { Spacer } from 'components/layouts/Spacer';
import { Heading5 } from 'components/typography';
import { useDive } from 'hooks/useDive';
import { useNotifications } from 'hooks/useNotifications';
import { useNotificationsAmount } from 'hooks/useNotificationsAmount';
import { usePressOutside } from 'hooks/usePressOutside';
import { useUpdateVisitedStatus } from 'hooks/useUpdateVisitedStatus';
import { usePathname } from 'next/navigation';
import { UIEventHandler, useRef, useState } from 'react';
import { twc } from 'react-twc';
import { NotificationIcon } from 'views/feeds/NotificationsFeed/NotificationIcon';
import { Notification } from './Notification';
import * as S from './NotificationsFeed.styles';

const NotificationBox = twc.div`
  border-greyscale-dark p-0 border-none border-y-[0px] border-x-[1px] medium:border-solid medium:px-2
`;

const ANIMATION_TIME = 1.2;
const NUMBER_OF_ANIMATION_CARDS = 9;

const randomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

export const NotificationsFeed = () => {
  const { updateVisitedStatus } = useUpdateVisitedStatus();
  const [isRefetching, setIsRefetching] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { amount, setNotificationsAmountToZero } = useNotificationsAmount();
  const [open, setOpen] = useState(false);
  const { notifications, fetchMore, loading, refetch } = useNotifications({
    skip: !open,
  });
  const pathname = usePathname() ?? '';
  const dive = useDive();
  const closeFeed = () => setOpen(false);
  const containerRef = usePressOutside({
    callback: closeFeed,
  });

  const toggleFeed = async e => {
    e.stopPropagation();
    if (!open) {
      dive.trackUserInteract('', pathname, 'notification_open', 'module');
    }
    setOpen(open => !open);

    if (amount !== 0 && !open) {
      setIsRefetching(true);
      refetch().then(() => {
        setIsRefetching(false);
        setNotificationsAmountToZero();
      });
    }
  };

  const edges = notifications?.edges ?? [];
  const pageInfo = notifications?.pageInfo;

  const markAllAsRead = async () => {
    if (edges.filter(e => !e.visited).length === 0) {
      return;
    }

    await updateVisitedStatus(null, true);
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();

    const hasReachedBottom =
      ref.current && ref.current.scrollTop >= ref.current.scrollHeight - ref.current.offsetHeight - 35;
    const fetchMoreResults = pageInfo?.hasNextPage && !loading && hasReachedBottom;

    if (fetchMoreResults) {
      fetchMore();
    }
  };

  const isLoading = loading || isRefetching;
  const placeholderAmount = edges.length > 0 && isLoading ? amount : NUMBER_OF_ANIMATION_CARDS;

  return (
    <div className="relative" data-cy="topBarNotificationsBell">
      <NotificationBox>
        <NotificationIcon />
        <Bell
          data-open={open}
          className="text-greyscale-lightGrey data-[open=true]:text-greyscale-white hover:text-greyscale-white block transition-all duration-fast cursor-pointer"
          size={40}
          title="Notifications"
          onClick={toggleFeed}
          role="button"
        />
      </NotificationBox>
      <S.NotificationsFeedContainer open={open} ref={containerRef} onScroll={e => e.stopPropagation()}>
        <S.NotificationHeader>
          <Heading5>Notifications</Heading5>
          <Button onClick={markAllAsRead} disabled={loading} text="Mark all as read" variety="text" size="medium" />
        </S.NotificationHeader>
        <S.NotificationContent ref={ref} onScroll={handleScroll}>
          {isLoading &&
            [...new Array(placeholderAmount)].map((_, index) => {
              return (
                <div
                  className="flex p-4"
                  data-testid="notifications-loading"
                  key={`${index}-notification-loading-card`}>
                  <CirclePlaceholder size={40} animationTime={ANIMATION_TIME} />
                  <Spacer width={16} />
                  <div className="flex flex-col w-1/2">
                    <LoadingCard
                      width={randomNumber(160, 300)}
                      height={16}
                      animationTime={ANIMATION_TIME}
                      borderRadius={8}
                    />
                    <Spacer height={4} />
                    <LoadingCard
                      width={randomNumber(160, 300)}
                      height={16}
                      animationTime={ANIMATION_TIME}
                      borderRadius={8}
                    />
                    <Spacer height={4} />
                    <LoadingCard
                      width={randomNumber(160, 300)}
                      height={16}
                      animationTime={ANIMATION_TIME}
                      borderRadius={8}
                    />
                  </div>
                </div>
              );
            })}
          {edges.map(notification => (
            <Notification
              closeNotifications={closeFeed}
              updateVisitedStatus={updateVisitedStatus}
              key={`${notification.id}-${notification.type}`}
              notification={notification}
            />
          ))}
        </S.NotificationContent>
      </S.NotificationsFeedContainer>
    </div>
  );
};
