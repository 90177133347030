import { DarkenBackground } from 'components/legacy/LightBox/DarkenBackground';
import { ModalProps } from 'components/legacy/modals/Modal.types';
import React, { useState, useEffect, useContext, useCallback, ReactElement, useMemo, ReactNode } from 'react';
import { isClientSide } from 'utils/isClientSide';

type OpenProps = Record<string, unknown>;
export type ModalContextType = {
  isOpen: boolean;
  open: (Modal: ReactElement, props?: OpenProps) => void;
  close: () => void;
  toggle: () => void;
};

const ModalContext = React.createContext<ModalContextType>({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  open: (Modal: ReactElement) => {
    /* */
  },
  close: () => {
    /* */
  },
  toggle: () => {
    /* */
  },
});

export const useModal = (Component: ReactElement | null) => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within ModalProvider');
  }

  const { open, ...rest } = context;

  const openModal = useCallback(
    (args?: OpenProps) => {
      if (Component) {
        open(Component, args);
      }
    },
    [open, Component]
  );

  return { open: openModal, ...rest };
};

export const useModalActions = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within ModalProvider');
  }

  return context;
};

const ModalRoot = ({ Modal, props }: { Modal: ReactElement | null; props?: ModalProps }) => {
  const isClient = isClientSide();

  const { isOpen, open, close } = useModal(Modal);

  useEffect(() => {
    if (!isClientSide()) {
      return;
    }

    const modalRoot = document.querySelector('#modal-root');
    const el = document.createElement('div');

    if (modalRoot) {
      modalRoot.appendChild(el);
    }

    return () => {
      if (modalRoot) {
        modalRoot.removeChild(el);
      }
    };
  }, [isOpen]);

  if (!isClient) {
    return null;
  }

  return (
    <DarkenBackground onClose={close} isOpen={isOpen}>
      {Modal
        ? React.cloneElement(Modal, {
            close,
            open,
            props,
          })
        : null}
    </DarkenBackground>
  );
};

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [{ Modal, lastTimeOpen, props }, setModal] = useState<{
    Modal: ReactElement | null;
    lastTimeOpen: number;
    props?: OpenProps;
  }>({
    Modal: null,
    lastTimeOpen: Date.now(),
  });

  const debounceTtime = 100;

  const close = useCallback(() => {
    setModal({
      Modal: null,
      lastTimeOpen: Date.now(),
    });
  }, []);

  const isOpen = !!Modal;

  const open = useCallback(
    (modal: ReactElement, props?: OpenProps) => {
      if (Date.now() - lastTimeOpen > debounceTtime) {
        setModal({
          Modal: modal,
          lastTimeOpen: Date.now(),
          props,
        });
      }
    },
    [lastTimeOpen, debounceTtime]
  );

  const toggle = useCallback(
    () =>
      setModal(s => ({
        ...s,
        modal: Modal ? null : Modal,
      })),
    [Modal]
  );

  const ctxValue = useMemo(() => ({ isOpen, open, close, toggle }), [isOpen, open, toggle, close]);

  return (
    <ModalContext.Provider value={ctxValue}>
      {children}
      <ModalRoot Modal={Modal} props={props} />
    </ModalContext.Provider>
  );
};
