import { ReactNode } from 'react';

const ProgressBar = ({ children, className, title }: { children: ReactNode; className?: string; title?: string }) => {
  return (
    <div className={`rounded-lg ${className || 'w-full h-2'}`} title={title}>
      {children}
    </div>
  );
};

const Rail = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <div className={`flex h-full rounded-lg ${className || 'bg-greyscale-dark'}`}>{children}</div>;
};

const Progress = ({ progress, className }: { progress: number; className?: string }) => {
  return (
    <div className={`rounded-lg ${className || 'bg-primary-main'}`} style={{ width: `${progress}%`, content: ' ' }} />
  );
};

ProgressBar.Rail = Rail;
ProgressBar.Progress = Progress;

export default ProgressBar;
