import { Checkmark, Close } from '@hiberworld/icons';
import { Spacer } from 'components/layouts/Spacer';
import { SpinningHiberHead } from 'components/common/spinners/Spinner';
import { useUserTagsQuery } from 'generated/graphql';
import { useMe } from 'hooks/useMe';
import { useFeatureToggles } from 'lib/useFeatureToggles';
import { isEnabledForUser } from 'views/pages/boss/FeatureTogglesPage/FeatureTogglesPage.helpers';
import { useTheme } from 'styled-components';
import { Modal } from '../Modal';
import * as S from '../Modal.styles';

export const FeatureTogglesModal = () => {
  const featureToggles = useFeatureToggles();
  const { me } = useMe();
  const tagsData = useUserTagsQuery({ variables: { username: me?.username ?? '' } });
  const theme = useTheme();

  if (featureToggles.loading) {
    return <SpinningHiberHead height={100} width={100} />;
  }

  return (
    <Modal maxWidth="unset">
      <div className="flex w-[400px] flex-col">
        <S.Headline>Feature Toggles</S.Headline>
        <Spacer height={20} />
        <S.Text>This is the feature toggle settings for you</S.Text>
        <Spacer height={40} />
        {featureToggles.featureToggles.map(feature => {
          const enabled = isEnabledForUser(
            {
              disabledAt: feature.disabledAt,
              enabledAt: feature.enabledAt,
              tags: feature?.criteria?.tags?.map(tag => tag.name),
            },
            tagsData.data?.userByUsername?.tags_v2
          );
          return (
            <>
              <S.Text textAlign="left" fontSize="16px">
                <div className="flex items-center">
                  {enabled ? <Checkmark color={theme.primaryColor.green} /> : <Close color={theme.primaryColor.red} />}

                  <Spacer width={8} />
                  {feature.name}
                </div>
              </S.Text>
              <Spacer height={4} />
              <S.Line />
              <Spacer height={4} />
            </>
          );
        })}
      </div>
    </Modal>
  );
};
