import { FindFeatureToggleQuery } from 'generated/graphql';
import { DiveExperiment } from 'hooks/useDive/types/DiveEvents';

export type ABTest = {
  id: string;
  group: string;
};

export const filterActiveABTests = (
  featureToggles: FindFeatureToggleQuery['findFeatureToggle'],
  tests: ABTest[]
): DiveExperiment[] => {
  const enabledExperiments = featureToggles?.values?.filter(featureToggle => featureToggle.isEnabled);

  const experimentCookies = tests.map(test => test.id);

  const experimentsHasCookieValues = enabledExperiments?.filter(experiment =>
    experimentCookies.includes(experiment.name)
  );

  const experiments: DiveExperiment[] = [];

  experimentsHasCookieValues?.forEach(experiment => {
    const cookieValue = tests.find(test => test.id === experiment.name);
    if (!cookieValue) {
      return;
    }
    experiments.push({
      id: experiment.name,
      group: cookieValue.group,
      provider: 'hiber',
    });
  });

  return experiments;
};
