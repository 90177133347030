import cookie from 'cookie';
import React, { useContext, useMemo } from 'react';
import { config } from 'config';
import { CookieContext, HiberCookies } from './cookies.context';

const { domain, environment } = config;

export const CookieProvider = ({ children, cookies }) => {
  const setCookie = (name: string, val: string, maxAge = 30 * 24 * 60 * 60) => {
    document.cookie = cookie.serialize(name, val, {
      maxAge, // 30 days
      domain: environment === 'development' ? domain : `.${domain}`,
      path: '/',
    });
  };

  const removeCookie = (name: string) => {
    document.cookie = cookie.serialize(name, '', {
      maxAge: -1, // Expire the cookie immediately
      domain: environment === 'development' ? domain : `.${domain}`,
      path: '/',
    });
  };

  const ctxValue = useMemo(
    () => ({
      cookies: cookies ? (cookie.parse(cookies) as HiberCookies) : {},
      setCookie,
      removeCookie,
    }),
    [cookies]
  );

  return <CookieContext.Provider value={ctxValue}>{children}</CookieContext.Provider>;
};

export const useCookies = () => {
  const { cookies, setCookie, removeCookie } = useContext(CookieContext);

  return { cookies, setCookie, removeCookie };
};
