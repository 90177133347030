import type { JwtPayload } from 'jsonwebtoken';

const decodeBase64 = (string: string) => Buffer.from(string, 'base64').toString('utf8');

export const decodeJWT = (token: string) => {
  // Extract 'data' part of JWT (second part of three)
  const dataB64 = token.split('.')[1];
  // Decode base64
  const dataJSON = decodeBase64(dataB64);
  // Parse JSON and return payload
  return JSON.parse(dataJSON) as JwtPayload;
};

/** Checks that string has valid JWT _format_. NOTE! Does not validate encoding or payload */
export const isJWT = (token: string) => !!token.match(/^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$/);
