import { Trophy, Verified } from '@hiberworld/icons';
import DangerousHtmlContent from 'components/common/DangerousHtmlContent';
import { Link, OptionalLink } from 'components/common/Link';
import RelativeDate from 'components/common/RelativeDate';
import { CircularImage } from 'components/common/images/CircularImage/CircularImage.styles';
import { usePreserveUrlParams } from 'components/common/links/LinkWithPreservedParams/LinkWithPreservedParams';
import { Label } from 'components/typography';
import { useDive } from 'hooks/useDive';
import { usePathname } from 'next/navigation';
import { useTheme } from 'styled-components';
import { z } from 'zod';
import { getIcon, getNotificationText, notificationsTypesMap } from './Notification.helpers';
import * as S from './Notification.styles';
import { NotificationProps } from './Notification.types';

const messageSchema = z.object({
  notification: z.object({
    title: z.string(),
    body: z.string().optional(),
  }),
  data: z
    .object({
      url: z.string(),
    })
    .optional(),
});

export const Notification = ({ closeNotifications, notification, updateVisitedStatus }: NotificationProps) => {
  const pathname = usePathname() ?? '';
  const searchParams = usePreserveUrlParams();
  const dive = useDive();
  const theme = useTheme();

  const {
    content: { linkTo, image, customData },
    lastUsers,
    type,
    createdAt,
    visited,
    message,
  } = notification;

  const parsedMessage = messageSchema.safeParse(JSON.parse(message ?? '{}'));
  const { pictureUrl, username } = lastUsers[0];
  const Icon = getIcon(type, customData?.gameKit);

  const linkHasParams = linkTo.href.includes('?');
  const contentLink = linkHasParams ? (searchParams ? `${linkTo.href}&${searchParams}` : linkTo.href) : linkTo.href;
  const userLink = searchParams ? `/user/${username}?${searchParams}` : `/user/${username}`;
  const announcementLink = parsedMessage.success ? `${parsedMessage.data.data?.url}?${searchParams}` : undefined;

  const link = type === notificationsTypesMap.ANNOUNCEMENT ? announcementLink : contentLink;

  const handleClick = () => {
    closeNotifications();
    updateVisitedStatus(notification, true);
  };

  const trackInteraction = (pageId: string) => {
    dive.trackUserInteract(pageId, pathname, 'notification_click', 'link');
  };

  const handleUserClick = () => {
    trackInteraction(linkTo.href);
  };

  const handleLinkToClick = () => {
    trackInteraction(linkTo.as);
  };

  return (
    <div className="relative bg-greyscale-almostBlack cursor-pointer" onClick={handleClick}>
      {!visited && <div className="flex absolute right-3 top-3 w-[10px] h-[10px] bg-text-error rounded-lg" />}
      <S.Notification>
        {type !== notificationsTypesMap.FEATURED_CONTENT && linkTo.href && (
          <div className="flex">
            <Link href={userLink} passHref onClick={handleUserClick}>
              <CircularImage src={pictureUrl} size={40} />
            </Link>
          </div>
        )}
        {type === notificationsTypesMap.FEATURED_CONTENT && (
          <Link href={contentLink} passHref onClick={handleLinkToClick}>
            <S.Trophy>
              <Trophy size={32} color={theme.colors.text.error} />
            </S.Trophy>
          </Link>
        )}
        <OptionalLink href={link}>
          <div className="flex flex-col justify-between h-full gap-1" onClick={handleLinkToClick}>
            <Label size="medium" color={theme.colors.text.info}>
              {type === notificationsTypesMap.ANNOUNCEMENT && parsedMessage.success ? (
                <>
                  <Label size="medium">{lastUsers[0].username}</Label>
                  <Verified size={16} style={{ transform: 'translate(0, -2px)', verticalAlign: 'top' }} />
                  &nbsp;
                  <Label size="medium" color={theme.colorTheme.greyscale.lightGrey}>
                    announced:
                  </Label>
                  &nbsp;
                  <Label size="medium">{parsedMessage.data.notification.title}</Label>
                </>
              ) : (
                <DangerousHtmlContent className="cursor-pointer" html={getNotificationText(notification)} />
              )}
            </Label>
            <div className="flex items-center gap-1">
              {type !== notificationsTypesMap.ANNOUNCEMENT && Icon}
              <Label color={theme.colors.text.info} size="medium" className="cursor-pointer">
                <RelativeDate value={createdAt} />
              </Label>
            </div>
            {image && <S.Thumbnail style={{ backgroundImage: `url(${image.url})` }} />}
          </div>
        </OptionalLink>
      </S.Notification>
    </div>
  );
};
