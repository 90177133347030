import { useApolloClient } from '@apollo/client';
import { CodeResponse, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { AuthButton } from 'components/common/buttons/AuthButton';
import { GoogleG } from 'components/logos/GoogleG';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import { config } from 'config';
import cookie from 'cookie';
import { AvatarProvider, useSignInWithGoogleCodeMutation } from 'generated/graphql';
import { useCreateDefaultAvatar } from 'hooks/useCreateDefaultAvatar';
import { useDive } from 'hooks/useDive';
import { setAuthCookies, setAuthLocalStorage } from 'lib/auth';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { usePathname, useRouter } from 'next/navigation';
import { useState } from 'react';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { GoogleAuthButtonProps, GoogleAuthProps, GoogleSigninProps } from './GoogleSignin.types';
import { isClientSide } from 'utils/isClientSide';

const GoogleAuthButton = ({
  onCompleted,
  loading,
  error,
  buttonVariant,
  buttonOrigin,
  className,
}: GoogleAuthButtonProps) => {
  const [warning, setWarning] = useState<string | null>(null);
  const dive = useDive();
  const pathname = usePathname();

  const onGoogleCodeFailure = (result: Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
    const googleError = `Sorry, Google login failed with code: ${result.error} - ${result.error_description}`;
    setWarning(googleError);
    dive.trackErrorCreatedCustom(
      'google_signin_error',
      pathname,
      `${result.error}:${result.error_description}`,
      '',
      buttonOrigin
    );
  };

  const GoogleLogIn = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: onCompleted,
    onError: onGoogleCodeFailure,
    redirect_uri: isClientSide() ? origin : '',
    ux_mode: 'popup',
  });

  const GoogleSignIn = async () => {
    GoogleLogIn();
    dive.trackUserInteract(pathname, '', 'continue_with_google', 'button', buttonOrigin);
  };

  return (
    <>
      <AuthButton
        className={className}
        disabled={!!error}
        onClick={GoogleSignIn}
        Icon={<GoogleG size={24} />}
        text="Continue with Google"
        busy={loading}
        variety={buttonVariant}
      />
      <ErrorMessage message={(warning || error) ?? ''} className="mt-2" />
    </>
  );
};

export const GoogleAuth = ({ onCompleted, loading, buttonVariant, buttonOrigin }: GoogleAuthProps) => {
  const clientId = config.google.oauthId;
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onScriptLoadSuccess = () => {
    setReady(true);
  };
  const onScriptLoadError = () => {
    setError('Google could not load');
  };

  return (
    <GoogleOAuthProvider
      clientId={clientId}
      onScriptLoadSuccess={onScriptLoadSuccess}
      onScriptLoadError={onScriptLoadError}>
      <GoogleAuthButton
        buttonVariant={buttonVariant || 'secondary'}
        loading={loading}
        ready={ready}
        error={error}
        onCompleted={onCompleted}
        buttonOrigin={buttonOrigin}
      />
    </GoogleOAuthProvider>
  );
};

export const GoogleSignInButton = ({
  navigateToUsername,
  ready,
  error,
  onCompleted,
  onCompleteRedirectPage,
  buttonVariant,
  className,
  buttonOrigin,
}: GoogleSigninProps) => {
  const [warning, setWarning] = useState<string | null>(null);
  const client = useApolloClient();
  const router = useRouter();
  const dive = useDive();
  const pathname = usePathname();
  const [createDefaultAvatar] = useCreateDefaultAvatar();

  const [signInWithGoogleCode, { loading }] = useSignInWithGoogleCodeMutation({
    onCompleted: async data => {
      const accessToken = data?.signInWithGoogleCode?.accessToken;
      const user = data?.signInWithGoogleCode?.user;
      const refreshToken = data?.signInWithGoogleCode?.refreshToken as string;

      if (!accessToken || !user) {
        return;
      }

      setAuthCookies({
        accessToken,
        userId: user?.id,
        refreshToken,
      });

      setAuthLocalStorage({
        accessToken,
        userId: user?.id,
        refreshToken,
      });

      postMessageToEngine({ type: 'LOGIN_WITH_DETAILS', payload: { accessToken, refreshToken } });

      const cookies = cookie.parse(document.cookie);

      dive.updateHeader({
        ...user,
        id: cookies.rid,
      });

      dive.trackUserLoggedIn({
        username: user.username,
        age: getAgeFromBirthdate(user.birthdate),
        publishedGames: user.totalPublishedGames || 0,
        loginType: 'google',
        authId: user.googleId ?? '',
        user,
      });

      dive.updateHeader(user);

      if (!user.avatarV2) {
        createDefaultAvatar({ provider: AvatarProvider.ReadyPlayerMe }); // Not awaited on purpose, to prevent blocking the 'pick username' modal
      }

      const notRegisteredOrNotAcceptedTos = !user.isRegistered || !user.hasAcceptedTos;
      if (notRegisteredOrNotAcceptedTos) {
        navigateToUsername();
        return;
      }
      await client.resetStore();
      onCompleted?.();

      if (onCompleteRedirectPage) {
        router.push(`user/${user.username}/${onCompleteRedirectPage}`);
      }
    },
  });

  const onGoogleCodeSuccess = async (result: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
    try {
      const res = await signInWithGoogleCode({
        variables: {
          code: result.code,
        },
      });

      if (res.errors && !res.data?.signInWithGoogleCode) {
        setWarning(res.errors[0].message);
        dive.trackErrorCreatedCustom('sign_in_with_google_code', pathname, res.errors[0].message, '', buttonOrigin);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <GoogleAuthButton
      className={className}
      buttonVariant={buttonVariant || 'secondary'}
      loading={loading}
      ready={ready}
      error={error ?? warning}
      onCompleted={onGoogleCodeSuccess}
      buttonOrigin={buttonOrigin}
    />
  );
};

export const GoogleSignIn = ({
  navigateToUsername,
  onCompleted,
  buttonVariant,
  buttonOrigin,
  className,
}: GoogleSigninProps) => {
  const clientId = config.google.oauthId;
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onScriptLoadSuccess = () => {
    setReady(true);
  };
  const onScriptLoadError = () => {
    setError('Google could not load');
  };

  return (
    <GoogleOAuthProvider
      clientId={clientId}
      onScriptLoadSuccess={onScriptLoadSuccess}
      onScriptLoadError={onScriptLoadError}>
      <GoogleSignInButton
        className={className}
        buttonVariant={buttonVariant || 'secondary'}
        navigateToUsername={navigateToUsername}
        ready={ready}
        error={error}
        onCompleted={onCompleted}
        buttonOrigin={buttonOrigin}
      />
    </GoogleOAuthProvider>
  );
};
