import styled, { css, CSSProperties, keyframes } from 'styled-components';

import {
  SkeletonRowStyleProps,
  CardWrapperStyleProps,
  GamePlaceholderStyleProps,
  LoadingCardStyleProps,
  ShapePlaceholderStyleProps,
} from './Skeleton.types';
import Theme from 'style/theme';

export const fade = keyframes`
  0% {
    background-color: ${Theme.colorTheme.greyscale.dark};
  }
  50% {
    background-color: ${Theme.colorTheme.greyscale.dark}AA;
  }
  100%{
    background-color: ${Theme.colorTheme.greyscale.dark};
  }
`;
export const hexagonFade = keyframes`
  0% {
    fill: ${Theme.colorTheme.greyscale.dark};
  }
  50% {
    fill: ${Theme.colorTheme.greyscale.dark}AA;
  }
  100%{
    fill: ${Theme.colorTheme.greyscale.dark};
  }
`;

const enableAnimation = ({
  disabled,
  animationDelay,
  animationInteration,
  animationTime,
}: LoadingCardStyleProps | ShapePlaceholderStyleProps) =>
  !disabled &&
  css`
    animation: ${fade} ${animationTime ?? 1.2}s ease;
    animation-delay: ${animationDelay ?? 0}s;
    animation-iteration-count: ${animationInteration ?? 'infinite'};
  `;

export const Row = styled.div<SkeletonRowStyleProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: calc(100% - 12px);
  max-width: ${({ maxWidth }) => maxWidth};
  margin: auto;
  row-gap: 24px;
  margin-bottom: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    gap: 10px;
    margin-bottom: 15px;
    row-gap: 15px;
  }
`;

export const CardWrapper = styled.div<CardWrapperStyleProps>`
  width: ${({ desktopWidth }) => desktopWidth};
  height: auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    width: ${({ tabletWidth }) => tabletWidth};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    width: ${({ width }) => width};
  }
`;

export const getSize = (size: string | number) => {
  if (typeof size === 'string') {
    return size;
  }
  return `${size}px`;
};

export const LoadingCard = styled.div<LoadingCardStyleProps>`
  width: ${({ width }) => (width ? getSize(width) : '100%')};
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.primaryColor.appBlack};
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : 0)};
  ${enableAnimation};
  animation: ${fade} ${({ animationTime }) => animationTime ?? 1.2}s ease;
  animation-delay: ${({ animationDelay }) => animationDelay ?? 0}s;
  animation-iteration-count: ${({ animationInteration }) => animationInteration ?? 'infinite'};
  height: ${({ height }) => (height ? getSize(height) : '')};
  min-width: ${({ minWidth }) => (minWidth ? getSize(minWidth) : 'unset')};
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

export const LoadingCardStyleable = styled.div<LoadingCardStyleProps>`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.primaryColor.appBlack};
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : 0)};
  ${enableAnimation};
  animation: ${fade} ${({ animationTime }) => animationTime ?? 1.2}s ease;
  animation-delay: ${({ animationDelay }) => animationDelay ?? 0}s;
  animation-iteration-count: ${({ animationInteration }) => animationInteration ?? 'infinite'};
  min-width: ${({ minWidth }) => (minWidth ? getSize(minWidth) : 'unset')};
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

export const GamePlaceholder = styled.div<GamePlaceholderStyleProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.primaryColor.appBlack};
  border-radius: 8px;
  animation: ${fade} 1.2s infinite ease;
  height: ${({ desktopHeight }) => desktopHeight};
  max-height: 191px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    height: ${({ tabletHeight }) => tabletHeight};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    height: ${({ height }) => height};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  width: calc(100% - 48px);
`;

export const TextPlaceholder = styled.div<{
  width?: number | string;
  height?: number | string;
  margin?: CSSProperties['margin'];
  marginLeft?: CSSProperties['marginLeft'];
  marginRight?: CSSProperties['marginRight'];
  marginBottom?: CSSProperties['marginBottom'];
  marginTop?: CSSProperties['marginTop'];
}>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height = '12px' }) => height};
  margin: ${({ margin }) => margin};
  margin-right: ${({ marginRight }) => marginRight};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.primaryColor.appBlack};
  animation: ${fade} 1.2s infinite ease;
`;

export const FirstTextPlaceholder = styled.div`
  width: 75%;
  height: 12px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.primaryColor.appBlack};
  animation: ${fade} 1.2s infinite ease;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    height: 16px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    width: 65%;
  }
`;

export const SecondTextPlaceholder = styled(FirstTextPlaceholder)`
  width: 30%;
  margin-top: 6px;
  background-color: ${({ theme }) => theme.primaryColor.appBlack};
  animation: ${fade} 1.2s infinite ease;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    height: 16px;
  }
`;

export const H1TextPlaceholder = styled(FirstTextPlaceholder)`
  height: 2.1rem;
  width: 40%;
  margin-bottom: 0.5rem;
  background-color: ${({ theme }) => theme.primaryColor.appBlack};
  animation: ${fade} 1.2s infinite ease;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    height: 2.1rem;
  }
`;

export const CirclePlaceholder = styled.div.attrs({ 'data-testid': 'placeholderCircle' })<ShapePlaceholderStyleProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor.appBlack};
  animation: ${fade} ${({ animationTime }) => animationTime ?? 1.2}s ease;
  animation-delay: ${({ animationDelay }) => animationDelay ?? 0}s;
  animation-iteration-count: ${({ animationInteration }) => animationInteration ?? 'infinite'};
`;

export const HexagonPlaceholder = styled.svg.attrs({
  'data-testid': 'placeholderHexagon',
})<ShapePlaceholderStyleProps>`
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  fill: ${({ theme }) => theme.primaryColor.appBlack};
  animation: ${hexagonFade} ${({ animationTime }) => animationTime ?? 1.2}s ease;
  animation-delay: ${({ animationDelay }) => animationDelay ?? 0}s;
  animation-iteration-count: ${({ animationInteration }) => animationInteration ?? 'infinite'};
`;
