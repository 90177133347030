import { useState } from 'react';
import { TwcComponentProps, twc } from 'react-twc';
import { Button, ButtonProps } from './Button';

const CollapsibleButton = twc(Button)(
  ({ $collapse, $expand }: { $collapse: boolean; $expand: boolean } & TwcComponentProps<'button'>) => [
    $collapse
      ? 'rounded-full justify-center min-w-9 gap-0 h-9 transition-all duration-fast delay-[10] pr-1 pl-2 flex'
      : 'mediumLarge:gap-1 mediumLarge:pr-4 mediumLarge:pl-3',
    $expand ? 'mediumLarge:pr-4 mediumLarge:pl-3 ' : '',
  ]
);

export const CollapsibleTextButton = ({ text, collapsed, ...props }: ButtonProps & { collapsed: boolean }) => {
  const [expanded, setExpanded] = useState(false);
  const handleMouseEnter = () => {
    setExpanded(true);
  };
  const handleMouseLeave = () => {
    setExpanded(false);
  };

  return (
    <CollapsibleButton
      $collapse={collapsed}
      $expand={expanded}
      className={'rounded-full justify-center gap-0 pr-1 pl-2 h-9'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}>
      <span
        data-collapsed={collapsed}
        data-text={expanded}
        className={
          'grid grid-cols-[0fr] data-[text=true]:mediumLarge:grid-cols-[1fr] data-[collapsed=false]:mediumLarge:grid-cols-[1fr] transition-all delay-[10] duration-fast'
        }>
        <span className="overflow-hidden text-nowrap">{text}</span>
      </span>
    </CollapsibleButton>
  );
};
