import { UserRole } from 'views/dialogs/BossUserDialog/BossUserDialog.types';
import { User } from 'generated/graphql';

export const isSystemOwnerUser = (user?: Pick<User, 'role'> | null) => {
  if (!user?.role) {
    return false;
  }

  return user.role >= UserRole.SYSTEM_OWNER;
};

export const isAdminUser = (user?: Pick<User, 'role'> | null) => {
  if (!user?.role) {
    return false;
  }

  return user.role >= UserRole.ADMIN;
};

export const isModeratorUser = (user?: Pick<User, 'role'> | null) => {
  if (!user?.role) {
    return false;
  }

  return user.role >= UserRole.MODERATOR;
};
