import { ButtonHTMLAttributes, forwardRef } from 'react';
import { Spinner as DefaultSpinner } from '../spinners/Spinner';
import { TwcComponentProps, twc } from 'react-twc';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variety: 'primary' | 'secondary' | 'secondaryDark' | 'text';
  size: 'small' | 'medium' | 'large';
  text?: string | number;
  Icon?: JSX.Element;
  spinner?: JSX.Element;
  fullWidth?: boolean;
  fullRound?: boolean;
  busy?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
  as?: React.ElementType;
  href?: string;
  target?: string;
  className?: string;
};

type BaseButtonProps = TwcComponentProps<'button'> & {
  $variety: 'primary' | 'secondary' | 'secondaryDark' | 'text';
  $size: 'small' | 'medium' | 'large';
  $fullWidth?: boolean;
  $fullRound?: boolean;
  $iconPadding?: boolean;
};

export const BaseBtn = twc.button<BaseButtonProps>(({ $size, $variety, $fullWidth, $fullRound, $iconPadding }) => [
  `text-greyscale-white font-semibold transition-colors duration-200 cursor-pointer disabled:text-greyscale-lightGrey disabled:cursor-not-allowed border-none flex justify-center items-center gap-1 tabular-nums`,
  $size === 'small' && 'h-8 text-button_small leading-3 px-3',
  $size === 'medium' && 'h-10 text-button_medium leading-[14px] px-4',
  $size === 'large' && 'h-12 text-button_large leading-4 px-6',
  $variety === 'primary' &&
    'bg-primary-main  hover:bg-primary-light active:bg-primary-dark disabled:bg-greyscale-veryDark',
  $variety === 'secondary' &&
    'bg-greyscale-veryDark hover:bg-greyscale-dark active:bg-greyscale-almostBlack disabled:bg-greyscale-veryDark',
  $variety === 'secondaryDark' &&
    'bg-greyscale-almostBlack hover:bg-greyscale-veryDark active:bg-greyscale-hiberBlack disabled:bg-greyscale-almostBlack',
  $variety === 'text' &&
    'bg-greyscale-hiberBlack outline-greyscale-dark bg-opacity-0  hover:outline hover:outline-solid active:outline-greyscale-white',
  $fullWidth && 'w-full',
  $fullRound ? 'rounded-full' : 'rounded-lg',

  $iconPadding && 'pl-2.5',
]);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      variety,
      onClick,
      Icon,
      spinner,
      text,
      busy,
      disabled,
      children,
      fullWidth,
      fullRound,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <BaseBtn
        $size={size}
        $variety={variety}
        $fullWidth={fullWidth}
        $fullRound={fullRound}
        $iconPadding={!!Icon && !!text}
        className={className}
        onClick={onClick}
        ref={ref}
        disabled={disabled || busy}
        {...props}>
        {busy ? (
          spinner ?? <DefaultSpinner white center />
        ) : (
          <>
            {Icon}
            {text}
            {children}
          </>
        )}
      </BaseBtn>
    );
  }
);
