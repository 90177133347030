import { GoogleSignIn } from 'components/globals/GoogleSignin/GoogleSignin';
import { Heading4, Label } from 'components/typography';
import { SigninMutation } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useLogin } from 'hooks/useLogin';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { useRouter } from 'next/navigation';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { DynamicWalletSignInButton } from 'views/global/Wallet/DynamicWalletSignInButton';
import { LoginForm } from 'views/forms/LoginForm/LoginForm';
import { LoginDialogProps } from './AuthDialog.types';
import { phrases } from 'constants/phrases';

export const LoginDialog = ({
  navigateToSignUp,
  navigateToUsername,
  navigateToForgotPassword,
  onLoginCompleted,
  onCompleteRedirectPage,
  onClose,
  title,
}: LoginDialogProps) => {
  const dive = useDive();
  const { login } = useLogin();
  const router = useRouter();

  const onLoginSuccess = async (authResponse: SigninMutation['loginUser']) => {
    const loginInput = {
      accessToken: authResponse?.accessToken,
      refreshToken: authResponse?.refreshToken,
      userId: authResponse?.user?.id,
    };

    await login(loginInput);
    await onLoginCompleted?.();

    if (onCompleteRedirectPage) {
      router.push(`user/${authResponse?.user.username}/${onCompleteRedirectPage}`);
    }

    onClose?.();
  };

  const onAlternateSignInComplete = () => {
    localStorage.removeItem('worlds_visited');
    postMessageToEngine('LOGIN_SUCCESS');
    onClose?.();
    onLoginCompleted?.();
  };

  const onNavigateToSignUp = () => {
    dive.trackModalOpen({ name: 'sign_up', origin: 'login', description: phrases.authDialog.signUp.defaultHeadline });
    navigateToSignUp();
  };

  const onSubmit = async (loginUser: SigninMutation['loginUser']) => {
    const user = loginUser?.user;

    if (user) {
      dive.trackUserLoggedIn({
        username: user.username,
        age: getAgeFromBirthdate(user.birthdate),
        publishedGames: user.totalPublishedGames || 0,
        loginType: 'user',
        authId: '',
        user,
      });
    }
    await onLoginSuccess(loginUser);
  };

  return (
    <>
      <div className="px-8  mediumSmall:px-16">
        <div className="mx-7">
          <Heading4 className="px-10 small:px-0 mb-12 pt-4 text-center text-greyscale-white">
            {title ?? phrases.authDialog.login.defaultHeadline}
          </Heading4>
          <LoginForm onLoginSuccess={onSubmit} onForgotPassword={navigateToForgotPassword} color="dark" />
        </div>
        <div className="h-[1px] bg-greyscale-mediumGrey mt-6 mb-8" />
        <div className="px-8 pb-4">
          <GoogleSignIn
            navigateToUsername={navigateToUsername}
            onCompleted={onAlternateSignInComplete}
            onCompleteRedirectPage={onCompleteRedirectPage}
            buttonVariant="secondaryDark"
          />
          <DynamicWalletSignInButton navigateToUsername={navigateToUsername} onCompleted={onAlternateSignInComplete} />
          <div className="flex justify-center mt-8">
            <Label className={`text-center text-greyscale-veryLightGrey block`} size="medium">
              Don&apos;t have an account?{' '}
              <span
                className="font-bold text-greyscale-veryLightGrey hover:text-primary-main cursor-pointer"
                onClick={onNavigateToSignUp}>
                Sign up
              </span>
            </Label>
          </div>
        </div>
      </div>
    </>
  );
};
