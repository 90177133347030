import {
  Notification,
  NotificationsDocument,
  NotificationsQuery,
  useUpdateVisitedStatusMutation,
} from 'generated/graphql';

type NotificationArg = (Pick<Notification, 'id' | 'sourceId'> & { type: number }) | null;

export const useUpdateVisitedStatus = () => {
  const [update] = useUpdateVisitedStatusMutation();

  return {
    updateVisitedStatus(notification: NotificationArg, status: boolean) {
      const { id, sourceId, type } = notification || {};
      return update({
        variables: {
          id,
          sourceId,
          status,
          type,
        },
        refetchQueries: [
          {
            query: NotificationsDocument,
          },
        ],
        awaitRefetchQueries: true,
        update: store => {
          const data = store.readQuery<NotificationsQuery>({ query: NotificationsDocument });
          if (data?.me?.notifications) {
            data.me.notifications.edges = data.me.notifications.edges.map(edge => ({ ...edge, visited: true }));
            store.writeQuery({ query: NotificationsDocument, data });
          }
        },
      });
    },
  };
};
