import React, { SelectHTMLAttributes, forwardRef } from 'react';
import { useTheme } from 'styled-components';
import * as S from './Select.styles';

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  children?: React.ReactNode;
  color?: 'dark';
  hideIcon?: boolean;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ children, placeholder, defaultValue, color, hideIcon, ...props }, ref) => {
    const theme = useTheme();
    return (
      <div className="flex relative w-full items-center">
        <S.HiberSelect
          ref={ref}
          defaultValue={defaultValue ?? ''}
          data-color={color}
          className={'data-[color=dark]:bg-greyscale-almostBlack'}
          {...props}>
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {children}
        </S.HiberSelect>
        {!hideIcon && <S.DownIcon color={theme.colors.text.default} size="1.5rem" />}
      </div>
    );
  }
);
