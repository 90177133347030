import React from 'react';
import { SpinnerProps, SpinnerSvg } from './spinner.styles';
import { useTheme } from 'styled-components';

export const Spinner = ({
  small = true,
  center,
  noMargin,
  red,
  white,
  big,
  className,
  medium,
  mini,
  tiny,
}: SpinnerProps) => {
  const theme = useTheme();
  const color = red ? '#E81623' : white ? theme.colors.text.info : '#09B584';

  return (
    <SpinnerSvg
      mini={mini}
      tiny={tiny}
      small={small}
      medium={medium}
      big={big}
      center={center}
      noMargin={noMargin}
      className={className}
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      data-testid="loading-spinner"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="loading-spinner">
      <title id="loading-spinner">loading...</title>
      <circle
        cx="35.0002"
        cy="35"
        r="23.2487"
        stroke={color}
        strokeWidth="8"
        strokeDasharray="276.460 276.460"
        strokeLinecap="round"
      />
    </SpinnerSvg>
  );
};
