export const FEATURE_TOGGLE_DAO_UNLIMITED_PLAYERS_IN_ROOM = 'daoUnlimitedPlayersInRoom' as const;
export const FEATURE_TOGGLE_SHOW_EDIT_WORLD_BUTTONS = 'showEditWorldButtons' as const;

export const FEATURE_TOGGLE_PREFAB_CREATOR = 'prefabCreator' as const;
export const FEATURE_TOGGLE_WORLD_CREATOR = 'worldCreator' as const;
export const FEATURE_TOGGLE_WORLD_CREATOR_V2 = 'worldCreatorByTagDef' as const;
export const FEATURE_TOGGLE_WORLD_CREATOR_V3 = 'worldCreatorV3' as const;
export const FEATURE_TOGGLE_PINNED_WORLDS = 'pinnedWorlds' as const;
export const FEATURE_TOGGLE_ACTIVE_FRIENDS = 'activeFriends' as const;

export const FEATURE_TOGGLE_PLAY_ROUTE = 'playRoute' as const;
export const FEATURE_TOGGLE_SIGN_UP_REWARD_PROMPT = 'signUpRewardPrompt' as const;

export const FEATURE_TOGGLE_DAILIES_ON_HOME_PAGE = 'dailiesOnHomePage' as const;

export const FEATURE_TOGGLE_NEW_LEADERBOARD = 'newLeaderboard' as const;
export const FEATURE_TOGGLE_NEW_LEADERBOARD_ON_WEB = 'useOnWeb' as const;

export const FEATURE_TOGGLE_AVATAR_PROFILE_PICTURE = 'avatarProfilePicture' as const;

export const FEATURE_TOGGLE_STASH = 'stash' as const;
export const FEATURE_TOGGLE_STASH_BUY_HIBUX = 'stash_buy_hibux' as const;

export const FEATURE_TOGGLE_TYPESENSE_SEARCH = 'typesense-search' as const;
export const FEATURE_TOGGLE_TYPESENSE_SEARCH_PAGE = 'typesense-search-page' as const;

export const FEATURE_TOGGLE_USER_TITLES = 'userTitles' as const;

export const FEATURE_TOGGLE_DAILY_REWARDED_AD = 'dailyRewardedAd' as const;
