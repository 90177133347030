import { Search } from '@hiberworld/icons';
import { SearchField } from 'components/legacy/fields';
import { FEATURE_TOGGLE_TYPESENSE_SEARCH } from 'constants/featureToggle.constants';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { TwcComponentProps, twc } from 'react-twc';

type SearchBarProps = TwcComponentProps<'div'> & {
  isOpen: boolean;
};

export const SearchBarContainer = twc.div.transientProps(['isOpen'])<SearchBarProps>(({ isOpen }) => [
  `grid absolute bg-navigation-background items-end grid-cols-[1fr] left-0 w-full top-full overflow-hidden transition-all duration-300 ease-in-out 
  medium:block medium:relative medium:bg-opacity-0 medium:top-0 medium:p-0 medium:transition-colors mediumLarge:min-w-[244px]`,
  isOpen ? 'grid-rows-[1fr] p-4' : 'grid-rows-[0fr] px-4',
]);

const SearchFormContainer = twc.div`
  overflow-auto medium:overflow-hidden medium:row-start-1 medium:row-end-2
`;

const allowedSearchCharacters = /^[a-zA-Z0-9_.,'"&:*?!/[\]()| ]{0,25}$/;

export const SearchBar = ({ isOpen = false }: { isOpen?: boolean }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const routeParams = useParams();
  const searchParams = useSearchParams();
  const typesense = useFeatureToggle(FEATURE_TOGGLE_TYPESENSE_SEARCH).isEnabled;

  const handleChange = (text: string) => {
    let newText = text;
    if (!typesense) {
      newText = text
        .split('-')
        .filter(Boolean)
        .map(char => {
          if (allowedSearchCharacters.test(char)) {
            return char;
          }
          return null;
        })
        .filter(Boolean)
        .join(' ');
    }

    setQuery(newText);
  };

  const [query, setQuery] = useState(routeParams?.searchTerm as string);

  const handleSearch = (text: string) => {
    if (text) {
      let type: string | undefined = searchParams?.get('type') ?? 'worlds';

      if (typesense) {
        type = searchParams?.get('type') ?? undefined;
      }

      let url = `/search/${encodeURIComponent(text)}`;

      if (type) {
        url += `?type=${type}`;
      }

      router.push(url);
    }
  };

  useEffect(() => {
    if (inputRef.current && isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <SearchBarContainer isOpen={isOpen}>
      <SearchFormContainer>
        <SearchField
          value={query}
          onEnter={handleSearch}
          Icon={<Search size="1.5rem" />}
          placeholder="Search"
          data-cy="topBarSearch"
          setValue={handleChange}
          ref={inputRef}
        />
      </SearchFormContainer>
    </SearchBarContainer>
  );
};
