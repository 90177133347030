import Link from 'next/link';
import { useEffect, useState } from 'react';

export const usePreserveUrlParams = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { search } = window.location;

      setUrl(search?.replace('?', ''));
    }
  }, []);

  return url;
};

export type LinkWithPreservedParamProps = Omit<Parameters<typeof Link>[0], 'as' | 'href'> & {
  pathname: string;
  additionalParams?: string[];
};

export const LinkWithPreservedParams = ({ pathname, additionalParams, ...props }: LinkWithPreservedParamProps) => {
  const searchParams = usePreserveUrlParams();

  const additionalParamsString =
    additionalParams
      ?.map((param, index) => {
        if (index === 0 && !searchParams) {
          return `?${param}`;
        }
        return `&${param}`;
      })
      .toString()
      .replace(/,/g, '') ?? '';

  const href = searchParams
    ? `${pathname}?${searchParams}${additionalParamsString}`
    : pathname + additionalParamsString;

  return <Link href={href} {...props} />;
};
