import React from 'react';
import { AuthDialogOptions, AuthDialogType } from 'components/common/AuthDialog/AuthDialog.types';

type AuthDialogContextType = {
  type: AuthDialogType;
  setType: (type: AuthDialogType) => void;
  options: AuthDialogOptions;
  setOptions: (object: AuthDialogOptions) => void;
};

export const AuthDialogContext = React.createContext<AuthDialogContextType>({
  type: 'SIGN_UP',
  setType: () => {},
  options: {},
  setOptions: () => {},
});
