import { User } from '@hiberworld/icons';
import { AuthButton } from 'components/common/buttons/AuthButton';
import { GoogleSignIn } from 'components/globals/GoogleSignin/GoogleSignin';
import { Spacer } from 'components/layouts/Spacer';
import { useDive } from 'hooks/useDive';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import Link from 'next/link';
import { DynamicWalletSignInButton } from 'views/global/Wallet/DynamicWalletSignInButton';
import { SignUpDialogProps } from './AuthDialog.types';
import { Heading4, Label, Paragraph } from 'components/typography';
import { phrases } from 'constants/phrases';

export const LoginLinkAndText = ({
  navigateToLogin,
  className,
}: {
  navigateToLogin: () => void;
  className?: string;
}) => {
  const dive = useDive();
  const onNavigateToLogin = () => {
    dive.trackModalOpen({ name: 'login', origin: 'sign_up', description: phrases.authDialog.login.defaultHeadline });
    navigateToLogin();
  };

  return (
    <Label className={`text-center text-greyscale-veryLightGrey block ${className}`} size="medium">
      {phrases.alreadyHaveAccount}{' '}
      <span
        className="text-greyscale-white hover:text-primary-main font-bold cursor-pointer"
        onClick={onNavigateToLogin}>
        {phrases.logIn}
      </span>
    </Label>
  );
};

export const SignUpDialog = ({
  hideLoginOption,
  onClose,
  onCompleteRedirectPage,
  onSignUpCompleted,
  navigateToLogin,
  navigateToSignupWithEmail,
  navigateToUsername,
  rewardSoftCurrency,
  title,
}: SignUpDialogProps) => {
  const dive = useDive();

  const onAlternateSignUpComplete = async () => {
    localStorage.removeItem('worlds_visited');
    postMessageToEngine('LOGIN_SUCCESS');
    await onSignUpCompleted?.();
    onClose?.();
  };

  const onNavigateToSignupWithEmail = () => {
    dive.trackModalOpen({
      name: 'sign_up_with_email',
      origin: 'sign_up',
      description: phrases.authDialog.signUp.defaultHeadline,
    });
    navigateToSignupWithEmail();
  };

  const onNavigateToUsername = () => {
    dive.trackModalOpen({ name: 'username', origin: 'sign_up', description: 'Pick a username' });

    navigateToUsername();
  };

  if (rewardSoftCurrency && !hideLoginOption) {
    hideLoginOption = true;
  }

  return (
    <div className="landscape:max-mediumLarge:flex landscape:max-mediumLarge:gap-8 px-8 small:px-16  mediumSmall:px-24 landscape:max-mediumLarge:!px-8  pt-12 landscape:max-mediumLarge:pt-0 pb-6">
      <div className="landscape:max-mediumLarge:flex flex-col justify-center landscape:max-mediumLarge:max-w-64">
        <Heading4 className="px-10 small:px-0 mb-3 text-center text-greyscale-white">
          {title ?? phrases.authDialog.signUp.defaultHeadline}
        </Heading4>
        <Paragraph size="medium" className="text-center mb-10">
          {phrases.authDialog.signUp.createAccount}
        </Paragraph>
      </div>
      <div>
        <AuthButton
          onClick={onNavigateToSignupWithEmail}
          Icon={<User size={24} />}
          text={'Sign up with email'}
          variety="secondaryDark"
          className="mb-4"
        />
        <GoogleSignIn
          buttonVariant="secondaryDark"
          navigateToUsername={onNavigateToUsername}
          onCompleted={onAlternateSignUpComplete}
          onCompleteRedirectPage={onCompleteRedirectPage}
        />
        {/* Support soft currency reward for wallet sign ups? The Wallet Service creates a non-registered users so we have to re-evaluate that as well */}
        <DynamicWalletSignInButton
          navigateToUsername={onNavigateToUsername}
          onCompleted={onAlternateSignUpComplete}
          onCompleteRedirectPage={onCompleteRedirectPage}
        />
        <Spacer height={16} />
        <Label size="medium" className="text-center text-greyscale-mediumGrey inline-block">
          By continuing, you are agreeing to the HiberWorld{' '}
          <Link
            className="text-greyscale-veryLightGrey"
            target="_blank"
            rel="noreferrer"
            href="https://hiber3d.com/terms-of-use/">
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link
            className="text-greyscale-veryLightGrey"
            target="_blank"
            rel="noreferrer"
            href="https://hiber3d.com/privacy-policy/">
            Privacy Policy
          </Link>
        </Label>

        {!hideLoginOption && (
          <>
            <LoginLinkAndText className="mt-10 mb-4" navigateToLogin={navigateToLogin} />
          </>
        )}
      </div>
    </div>
  );
};
