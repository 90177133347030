import { useApolloClient } from '@apollo/client';
import { useCreateDefaultAvatar } from 'hooks/useCreateDefaultAvatar';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { SignUpWithEmailDialogProps } from './AuthDialog.types';
import { AuthResponse, AvatarProvider } from 'generated/graphql';
import { setAuthCookies, setAuthLocalStorage } from 'lib/auth';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import CreateAccountForm from 'views/forms/CreateAccountForm/CreateAccountForm';
import { Label } from 'components/typography/Label';
import { GoogleSignIn } from 'components/globals/GoogleSignin/GoogleSignin';
import Link from 'next/link';
import { Heading4 } from 'components/typography';
import { phrases } from 'constants/phrases';

export const SignUpWithEmailDialog = ({
  hideLoginOption,
  navigateToLogin,
  navigateToUsername,
  onClose,
  onSignUpCompleted,
  onSignUpPage,
  onCompleteRedirectPage,
  rewardSoftCurrency,
}: SignUpWithEmailDialogProps) => {
  const { me, isLoggedIn } = useMe();
  const params = useSearchParams();
  const client = useApolloClient();
  const [createDefaultAvatar] = useCreateDefaultAvatar();
  const [loading, setLoading] = useState(false);
  const createDaoGame = params?.get('createDaoGame');
  const { googleId } = me ?? {};
  const dive = useDive();
  const router = useRouter();
  const [isMobileLandscape, setIsMobileLandscape] = useState(false);

  useEffect(() => {
    updateIsMobileLandscape();
    window.addEventListener('resize', () => {
      updateIsMobileLandscape();
    });
  });

  useEffect(() => {
    if (isLoggedIn && !googleId && !createDaoGame && !loading) {
      onClose?.();
    }
  }, [googleId, isLoggedIn, createDaoGame, onClose, loading]);

  const updateIsMobileLandscape = () => {
    const isLandscapeMobile = window.matchMedia('(max-width: 1024px) and (orientation: landscape)').matches;
    setIsMobileLandscape(isLandscapeMobile);
  };

  const onRegisterSuccess = async (authResponse: AuthResponse, type: string) => {
    const accessToken = authResponse?.accessToken;
    const user = authResponse?.user;
    const refreshToken = authResponse?.refreshToken as string;

    setLoading(true);

    if (!accessToken || !user) {
      return;
    }

    setAuthCookies({
      accessToken,
      userId: user?.id,
      refreshToken,
    });

    setAuthLocalStorage({
      accessToken,
      userId: user?.id,
      refreshToken,
    });

    client.clearStore();
    client.cache.reset();
    client.resetStore();

    if (type !== 'email') {
      navigateToUsername();
      return;
    }

    await createDefaultAvatar({ provider: AvatarProvider.ReadyPlayerMe });

    await onSignUpCompleted?.();

    localStorage.removeItem('worlds_visited');

    postMessageToEngine({ type: 'LOGIN_WITH_DETAILS', payload: { accessToken, refreshToken } });
    postMessageToEngine('LOGIN_SUCCESS');

    if (onCompleteRedirectPage) {
      router.push(`user/${authResponse?.user.username}/${onCompleteRedirectPage}`);
    }

    setLoading(false);

    onClose?.();
  };

  const onNavigateToLogin = () => {
    dive.trackModalOpen({ name: 'login', origin: 'sign_up', description: phrases.authDialog.login.defaultHeadline });
    navigateToLogin();
  };

  if (rewardSoftCurrency && !hideLoginOption) {
    hideLoginOption = true;
  }

  return (
    <div className="relative px-6 small:px-10 mediumSmall:px-16 landscape:max-mediumLarge:!px-8 landscape:max-mediumLarge:grid grid-cols-2 gap-8">
      <div
        data-signUpPage={onSignUpPage}
        className="landscape:max-mediumLarge:sticky top-0 data-[signUpPage=true]:top-20 landscape:max-mediumLarge:max-w-[284px]">
        <Heading4 className="small:px-7 pb-12 landscape:max-mediumLarge:pb-0 text-center text-greyscale-white">
          {phrases.authDialog.signUp.defaultHeadline}
        </Heading4>
        <div className="hidden landscape:max-mediumLarge:block">
          <div className="h-[1px] w-full bg-greyscale-mediumGrey my-8" />
          <div className="px-2 small:px-7 landscape:max-mediumLarge:!px-0">
            <GoogleSignIn
              buttonVariant="secondaryDark"
              navigateToUsername={navigateToUsername}
              onCompleted={onSignUpCompleted}
              onCompleteRedirectPage={onCompleteRedirectPage}
            />
          </div>
        </div>
      </div>
      <div className="px-2 small:px-7 landscape:max-mediumLarge:!px-0 col-start-2 grid-span-1 row-span-4">
        <CreateAccountForm
          busy={loading}
          onRegisterSuccess={onRegisterSuccess}
          rewardSoftCurrency={rewardSoftCurrency}
          color="dark"
          hideBirthdaySelectIcon={isMobileLandscape}
        />
        {!hideLoginOption && (
          <div className="flex mt-2">
            <Label size="medium" className="text-greyscale-veryLightGrey">
              Already have an account?&nbsp;
              <span
                className="text-greyscale-veryLightGrey hover:text-primary-main font-bold cursor-pointer"
                onClick={onNavigateToLogin}>
                {phrases.logIn}
              </span>
            </Label>
          </div>
        )}
        <Label size="medium" className="mt-5 text-greyscale-lightGrey text-center block">
          By continuing, you are agreeing to the HiberWorld
          <Link
            className="text-greyscale-veryLightGrey"
            rel="noreferrer"
            target="_blank"
            href="https://hiber3d.com/terms-of-use/">
            {' '}
            Terms of Use{' '}
          </Link>
          and
          <Link
            className="text-greyscale-veryLightGrey"
            rel="noreferrer"
            target="_blank"
            href="https://hiber3d.com/privacy-policy/">
            {' '}
            Privacy Policy{' '}
          </Link>
        </Label>
      </div>
      <div className="landscape:max-mediumLarge:hidden col-start-1 row-start-2">
        <div className="h-[1px] w-full bg-greyscale-mediumGrey my-8" />
        <div className="px-2 small:px-7 pb-20 landscape:max-mediumLarge:pb-0">
          <GoogleSignIn
            buttonVariant="secondaryDark"
            navigateToUsername={navigateToUsername}
            onCompleted={onSignUpCompleted}
            onCompleteRedirectPage={onCompleteRedirectPage}
          />
        </div>
      </div>
    </div>
  );
};
