interface DangerousHtmlContentProps {
  html?: string | null;
  className?: string;
}
const DangerousHtmlContent = ({ html, className }: DangerousHtmlContentProps) => {
  if (!html) {
    return null;
  }

  // eslint-disable-next-line react/no-danger
  return <div className={className} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default DangerousHtmlContent;
