import { AuthDialogContext } from 'context/AuthDialog/AuthDialog.context';
import { useContext } from 'react';
import { AuthDialogOptions, AuthDialogType } from './AuthDialog.types';
import { DialogOpenOptions, useDialog } from 'hooks/useDialog';
export const useAuthDialog = () => {
  const { type, setType, options, setOptions } = useContext(AuthDialogContext);
  const dialog = useDialog();
  const open = (
    type: AuthDialogType,
    trackingOptions: Omit<DialogOpenOptions, 'onOpen'>,
    authOptions: AuthDialogOptions = {}
  ) => {
    setOptions(authOptions);
    setType(type);
    dialog.open('authDialog', trackingOptions);
  };
  const close = () => dialog.close('authDialog');
  return { type, setType, options, setOptions, open, close };
};
