import { Label as BaseLabel } from 'components/typography';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: inline-block;
  :focus,
  :hover label {
    visibility: visible;
  }
`;

export const LabelWrapper = styled.span<{ $translateX: number; $reverse: boolean }>`
  position: absolute;
  top: 0;
  transform: ${({ $translateX, $reverse }) =>
    `translateX(${$translateX}px) translateY(${$reverse ? '105%' : '-125%'})`};
  pointer-events: none;
`;
export const Label = styled(BaseLabel)<{ $reverse: boolean }>`
  display: block;
  visibility: hidden;
  text-transform: none;
  text-align: center;
  background: ${({ theme }) => theme.primaryColor.appDarkGray};
  border-radius: 0.25rem;
  padding: 0.5rem;
  z-index: 21;
  user-select: none;
  white-space: nowrap;
  :before {
    left: calc(50% - 6px);
    content: ' ';
    position: absolute;
    top: ${({ $reverse }) => ($reverse ? '-11px' : '100%')};
    border-width: 6px;
    border-style: solid;
    border-color: ${({ theme, $reverse }) => ($reverse ? 'transparent' : theme.primaryColor.appDarkGray)} transparent
      ${({ theme, $reverse }) => ($reverse ? theme.primaryColor.appDarkGray : 'transparent')} transparent;
  }
`;
