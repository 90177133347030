import { useMe } from 'hooks/useMe';
import { useHideMobileLogInOrRegister } from './logInOrRegister.utils';
import { useAuthDialog } from 'components/common/AuthDialog/useAuthDialog';
import { Arrow } from '@hiberworld/icons';
import { phrases } from 'constants/phrases';

export const LogInOrRegister = () => {
  const { isLoggedIn } = useMe();
  const authDialog = useAuthDialog();
  const hideMobileLogin = useHideMobileLogInOrRegister();

  if (hideMobileLogin || isLoggedIn) {
    return null;
  }

  const signup = () => {
    authDialog.open('SIGN_UP', {
      description: phrases.authDialog.signUp.defaultHeadline,
      name: 'sign_up',
      origin: 'login_or_register_footer',
    });
  };

  const login = () => {
    authDialog.open('LOGIN', {
      description: phrases.authDialog.login.defaultHeadline,
      name: 'login',
      origin: 'login_or_register_footer',
    });
  };

  return (
    <>
      <div
        className="flex fixed overflow-hidden left-[10px] [width:calc(100%-20px)]
        items-center text-center z-10 rounded-t-lg      
        bg-accents-purple-primary shadow-md medium:hidden py-3 bottom-0">
        <div className="flex flex-1 justify-center items-center">
          <Arrow className="transform rotate-180 mr-1" />
          <div className="" role="button" onKeyDown={login} onClick={login}>
            Log in
          </div>
          <div className="px-1">or</div>
          <div className="" onKeyDown={signup} onClick={signup}>
            create a new account
          </div>
        </div>
      </div>
    </>
  );
};
