import formatDistance from 'date-fns/formatDistance';

type RelativeDateProps = {
  value: number | Date;
};

const RelativeDate = ({ value = 0 }: RelativeDateProps) => {
  const now = Date.now();

  value = +value || value || now; // if timestamp: cast to number
  const timeValue = new Date(value).getTime();

  return <>{formatDistance(timeValue, now, { addSuffix: true })}</>;
};

export default RelativeDate;
