import { GameKit } from 'generated/graphql';
import { isObject } from 'lib/utils';
import { DiveProjectType, PrivacySetting } from './types/DiveEvents';
import { PreviousPageData } from './useDive.types';

export const getPrivacySettingsAsString = (value: number): PrivacySetting => {
  switch (value) {
    case 1:
      return 'friendsOnly';
    case 2:
      return 'everyone';
    case 0:
    default:
      return 'onlyMe';
  }
};

const isPrevPageData = (data?: unknown): data is PreviousPageData =>
  isObject(data) && 'url' in data && 'timeStamp' in data;

export const getPreviousPageData = (): PreviousPageData => {
  const previousPageData = sessionStorage.getItem('previousPage');
  const parsed = previousPageData ? JSON.parse(previousPageData) : undefined;

  return isPrevPageData(parsed) ? parsed : { url: '', timeStamp: new Date().getTime() };
};

export const setPreviousPageData = (url: string) => {
  const timeStamp = new Date().getTime();

  const previousPageData: PreviousPageData = { url, timeStamp };

  sessionStorage.setItem('previousPage', JSON.stringify(previousPageData));
};

export const getProjectTypeFromEngineVersionAndGameKit = (gameKit: GameKit, engineVersion: number): DiveProjectType => {
  if (engineVersion > 2) {
    return 'dao';
  }

  return gameKit.toLocaleLowerCase() as DiveProjectType;
};
