import { useMemo, useState } from 'react';
import { AuthDialogContext } from './AuthDialog.context';
import { AuthDialogOptions, AuthDialogType } from 'components/common/AuthDialog/AuthDialog.types';

export const AuthDialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [type, setType] = useState<AuthDialogType>('SIGN_UP');
  const [options, setOptions] = useState<AuthDialogOptions>({});

  const ctxValue = useMemo(() => ({ type, setType, options, setOptions }), [type, options]);
  return <AuthDialogContext.Provider value={ctxValue}>{children}</AuthDialogContext.Provider>;
};
