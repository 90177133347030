import Image from 'next/image';
import { config } from 'config';
import { GradientBackground, GrowAnimation } from './spinner.styles';
import { ReactNode } from 'react';

const GradientWrapper = ({ children, gradientBackground }: { children: ReactNode; gradientBackground?: boolean }) => {
  if (!gradientBackground) {
    return <>{children}</>;
  }
  return <GradientBackground>{children}</GradientBackground>;
};

const GrowAnimationWrapper = ({ children, animated }) => {
  if (!animated) {
    return children;
  }

  return <GrowAnimation>{children}</GrowAnimation>;
};
export const SpinningHiberHead = ({
  animated,
  gradientBackground,
  width,
  height,
  fill,
}: {
  animated?: boolean;
  gradientBackground?: boolean;
  height?: number;
  width?: number;
  fill?: boolean;
}) => {
  const src = `${config.cdn}/static/logos/spinning_hiber_head.gif`;

  return (
    <GradientWrapper gradientBackground={gradientBackground}>
      <GrowAnimationWrapper animated={animated}>
        <Image src={src} height={height} width={width} fill={fill} alt="loading..." priority style={{ zIndex: 1 }} />
      </GrowAnimationWrapper>
    </GradientWrapper>
  );
};
