import { Link } from 'components/common/Link';
import Image from 'next/image';

export const HiberLogo = () => {
  return (
    <Link href="/">
      <Image
        className="h-10 block medium:hidden"
        alt="logo"
        src="https://cdn.hibervr.com/static/icons/HiberHead.svg"
        height={40}
        width={47}
      />
      <Image
        className="h-8 w-auto hidden medium:block"
        alt="logo"
        priority={true}
        src="https://cdn.hibervr.com/static/icons/HW+logo+BETA.svg"
        height={34}
        width={198}
      />
    </Link>
  );
};
