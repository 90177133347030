type LimitString = {
  text: string;
  replacement?: string;
  limit?: number;
};

export const limitString = (input: LimitString): string => {
  const { text, replacement = '...', limit = 24 } = input;

  if (text.length <= limit) {
    return text;
  }

  if (replacement) {
    return text.substring(0, limit - replacement.length) + replacement;
  }

  return text.substring(0, limit);
};
