import { useApolloClient } from '@apollo/client';
import { Button } from 'components/common/buttons/Button';
import { Dialog } from 'components/layouts/Dialog/Dialog';
import { config } from 'config';
import { FEATURE_TOGGLE_AVATAR_PROFILE_PICTURE } from 'constants/featureToggle.constants';
import {
  CurrentUserDocument,
  CurrentUserFragmentDoc,
  ProfilePageUserDocument,
  useCurrentUserAvatarQuery,
  useGenerateUploadPictureUrlMutation,
} from 'generated/graphql';
import { useDialog } from 'hooks/useDialog';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { isClientSide } from 'utils/isClientSide';
import { useUpdateProfile } from 'lib/useUpdateProfile';
import Link from 'next/link';

export const AVATAR_DIALOG_ID = 'avatarProfilePicture';
const AVATAR_IMAGE_HASH_LOCAL_STORAGE_KEY = 'avatarImageHashV2';

const AvatarProfilePictureAndTitle = dynamic(
  () => import('components/inputs/AvatarProfilePicture/AvatarProfilePictureAndTitle'),
  {
    loading: () => null,
    ssr: false,
  }
);

const useUploadPicture = () => {
  const { me } = useMe();
  const [updatePictureUrl, { loading }] = useGenerateUploadPictureUrlMutation();
  const client = useApolloClient();

  const uploadImage = (blob: Blob) =>
    new Promise<void>(resolve =>
      updatePictureUrl({
        variables: {
          format: 'jpeg',
        },
        onCompleted: data => {
          if (me && data.generateUploadPictureURL) {
            const url = data.generateUploadPictureURL;
            fetch(url, {
              method: 'PUT',
              body: blob,
            }).then(() => {
              const pathname = new URL(data.generateUploadPictureURL).pathname;
              client.writeFragment({
                id: client.cache.identify(me),
                fragment: CurrentUserFragmentDoc,
                data: {
                  ...me,
                  pictureUrl: `${config.cdn}${pathname}`,
                },
              });
              resolve();
            });
          }
        },
      })
    );

  return { uploadImage, loading };
};

export const HeadlessAvatarProfileChanger = ({ rpmViewerHash }: { rpmViewerHash?: string | null }) => {
  const { data } = useCurrentUserAvatarQuery({ ssr: false });
  const { uploadImage } = useUploadPicture();
  const avatarProfilePictureEnabled = useFeatureToggle(FEATURE_TOGGLE_AVATAR_PROFILE_PICTURE).isEnabled;

  const provider = data?.me?.avatarV2?.provider;

  if (!avatarProfilePictureEnabled || provider !== 'ReadyPlayerMe') {
    return null;
  }

  const avatarImageHash = isClientSide() ? localStorage.getItem(AVATAR_IMAGE_HASH_LOCAL_STORAGE_KEY) : null;
  rpmViewerHash ??= isClientSide() ? localStorage.getItem('rpmViewerHash') : 'not-set';

  const handleCompleted = (blob: Blob) => {
    const avatarId = data?.me?.avatarV2?.id;
    let shouldUpload = false;
    if (!rpmViewerHash) {
      localStorage.setItem('rpmViewerHash', 'default');
      shouldUpload = true;
    }
    if (rpmViewerHash && !avatarImageHash) {
      localStorage.setItem(AVATAR_IMAGE_HASH_LOCAL_STORAGE_KEY, rpmViewerHash);
      shouldUpload = true;
    }
    if (avatarId && avatarImageHash && rpmViewerHash && avatarImageHash !== rpmViewerHash) {
      localStorage.setItem(AVATAR_IMAGE_HASH_LOCAL_STORAGE_KEY, rpmViewerHash);
      shouldUpload = true;
    }
    if (shouldUpload && avatarId && blob) {
      uploadImage(blob!);
    }
  };

  if (avatarImageHash && rpmViewerHash && avatarImageHash === rpmViewerHash) {
    return null;
  }

  return <AvatarProfilePictureAndTitle onCompleted={handleCompleted} className="hidden" />;
};

export const AvatarProfilePictureDialog = () => {
  const [state, setState] = useState<{ blob?: Blob; background?: string; pose?: string }>();
  const dialog = useDialog();
  const { uploadImage, loading } = useUploadPicture();
  const { userProfileEdited } = useDive();
  const { me } = useMe();
  const [userTitle, setUserTitle] = useState(me?.title || '');
  const { updateUserTitle } = useUpdateProfile();

  const handleSave = async () => {
    if (state?.blob && me) {
      await uploadImage(state.blob)
        .then(() => dialog.close?.(AVATAR_DIALOG_ID))
        .then(() =>
          updateUserTitle({
            variables: { userTitle },
            refetchQueries: [CurrentUserDocument, ProfilePageUserDocument],
            awaitRefetchQueries: false,
          })
        );
      userProfileEdited(me, { avatar_color: state.background, avatar_pose: state.pose });
    }
  };

  return (
    <Dialog id={AVATAR_DIALOG_ID} className="w-full medium:max-w-[856px]">
      <AvatarProfilePictureAndTitle
        onCompleted={(blob, background, pose) => {
          setState({ blob, background, pose });
        }}
        userTitle={userTitle}
        onTitleChange={setUserTitle}
        className="mt-8 px-4 medium:px-8"
      />
      <div className="flex justify-between flex-row gap-4 mb-6 px-8 items-end">
        <div className="mb-1 text-greyscale-lightGrey text-label_medium max-medium:flex max-medium:flex-col max-medium:mb-0">
          Looking to change up your hairstyle or equip another outfit?
          <span className="font-bold text-greyscale-veryLightGrey max-medium:ml-0 ml-2 mt-2 max-medium:mt-1">
            <Link href="/avatar">Go to Avatar page</Link>
          </span>
        </div>
        <Button onClick={handleSave} busy={loading} size="medium" className="mt-4" variety="primary">
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export default AvatarProfilePictureDialog;
