import React from 'react';
import * as S from './Skeleton.styles';
import { LoaderProps } from './Skeleton.types';
import { TextLoader } from './TextLoader';

export const GameLoader: React.FC<LoaderProps> = ({
  width,
  tabletWidth,
  desktopWidth,
  height,
  tabletHeight,
  desktopHeight,
  showAuthor,
  showTitle,
}) => {
  return (
    <S.CardWrapper width={width} tabletWidth={tabletWidth} desktopWidth={desktopWidth}>
      <S.GamePlaceholder height={height} tabletHeight={tabletHeight} desktopHeight={desktopHeight} />

      <S.InfoWrapper>
        {showAuthor && <S.CirclePlaceholder size={40} marginRight={8} marginTop={8} />}
        {showTitle && <TextLoader twoRows={showAuthor} />}
      </S.InfoWrapper>
    </S.CardWrapper>
  );
};
