export const getAgeFromBirthdate = (birthdate?: Date | null): number | null => {
  if (!birthdate) {
    return null;
  }

  const now = new Date();

  const birth = new Date(birthdate);

  let age = now.getFullYear() - birth.getFullYear();
  const month = now.getMonth() - birth.getMonth();

  if (month < 0 || (month === 0 && now.getDate() < birth.getDate())) {
    age--;
  }

  return age;
};
