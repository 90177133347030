import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

const HiberInput = styled.input`
  box-sizing: border-box;
  border: none;
  background-color: ${({ theme }) => theme.colors.input.default.background};
  color: ${({ theme }) => theme.colors.input.default.text};
  font-size: ${({ theme }) => theme.typography.body.medium.size.web};
  font-weight: ${({ theme }) => theme.typography.body.medium.weight};
  line-height: ${({ theme }) => theme.typography.body.medium.lineHeight};
  font-style: ${({ theme }) => theme.typography.body.medium.style};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.colors.input.default.boxShadow};
  padding: 0.75rem 1rem;
  position: relative;
  resize: none;
  vertical-align: top;
  width: 100%;

  &:focus {
    box-shadow: ${({ theme }) => theme.colors.input.focus.boxShadow};
    color: ${({ theme }) => theme.colors.input.focus.text};
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.input.default.placeholder};
  }

  &:hover:not(:focus):not(:disabled):not(:invalid) {
    box-shadow: ${({ theme }) => theme.colors.input.hover.boxShadow};
    color: ${({ theme }) => theme.colors.input.hover.text};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.input.disabled.background};
    box-shadow: ${({ theme }) => theme.colors.input.disabled.boxShadow};
    color: ${({ theme }) => theme.colors.input.disabled.text};

    cursor: not-allowed;
    + div > svg {
      color: ${({ theme }) => theme.colors.input.disabled.text};
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.input.disabled.text};
    }
  }
`;

type TextInputIconProps = {
  Icon: ReactNode;
  onIconClick?: () => void;
};

type TextInputProps =
  | (InputHTMLAttributes<HTMLInputElement> & {
      id?: string;
      placeholder?: string;
      color?: 'dark';
    })
  | (InputHTMLAttributes<HTMLInputElement> & {
      id?: string;
      placeholder?: string;
      color?: 'dark';
    } & TextInputIconProps);

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ color, className, ...props }, ref) => {
  if ('Icon' in props) {
    const { Icon, onIconClick } = props;

    delete props?.onIconClick;

    return (
      <div className={'relative ' + className}>
        <HiberInput
          type="text"
          aria-label="text input"
          ref={ref}
          data-color={color}
          className={'data-[color=dark]:bg-greyscale-almostBlack'}
          {...props}
        />
        <div
          className="flex justify-center items-center absolute h-full right-0 top-0 px-4 [&>svg]:min-w-6 [&>svg]:min-h-6 [&>svg]:cursor-pointer"
          role="button"
          onClick={onIconClick}>
          {Icon}
        </div>
      </div>
    );
  }

  return (
    <HiberInput
      type="text"
      data-color={color}
      className={'data-[color=dark]:bg-greyscale-almostBlack ' + className}
      {...props}
      ref={ref}
    />
  );
});
