import { phrases } from 'constants/phrases';
import * as yup from 'yup';

export const schema = yup.object({
  username: yup
    .string()
    .min(3, phrases.usernameWrongLength)
    .max(18, phrases.usernameWrongLength)
    .matches(/(^\w+$)/, phrases.usernameInvalid)
    .required(phrases.usernameChooseEmpty),
  email: yup.string().email(phrases.emailInvalid),
  password: yup
    .string()
    .min(4, phrases.passwordTooShort)
    .max(256, phrases.passwordTooLong)
    .required(phrases.passwordChooseEmpty),
  day: yup.string().required(phrases.dayEmpty),
  month: yup.string().required(phrases.monthEmpty),
  year: yup.string().required(phrases.yearEmpty),
  birthdate: yup.string().required(phrases.underAgeText),
});
