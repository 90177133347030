import * as uuid from 'uuid';
import { config } from 'config';
import cookie from 'cookie';

const { domain, environment } = config;

export const createRandomUserId = (): string => {
  // Since cookiebot removes the rid cookie when accepting, we also save it in localStorage to get the same value
  let rid = localStorage.getItem('rid');

  if (!rid) {
    rid = uuid.v4();
    localStorage.setItem('rid', rid);
  }
  document.cookie = cookie.serialize('rid', rid, {
    maxAge: 9999999999,
    domain: environment === 'development' ? domain : `.${domain}`,
    path: '/',
  });

  return rid;
};
