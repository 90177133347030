import { useApolloClient } from '@apollo/client';
import { Shuffle } from '@hiberworld/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/common/buttons/Button';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import { OnCompleteRedirects } from 'components/common/AuthDialog/AuthDialog.types';
import { Heading3, Paragraph } from 'components/typography';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import { phrases } from 'constants/phrases';
import cookie from 'cookie';
import { useFinishRegistrationMutation, useRandomizedUsernamesLazyQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { DiveModalNames } from 'hooks/useDive/types/DiveEvents';
import { useMe } from 'hooks/useMe';
import { gtagRecordEvent } from 'lib/gtag';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { getGraphqlFieldErrors } from 'utils/getGraphqlFieldError';
import * as yup from 'yup';

const schema = yup.object().shape({
  username: yup
    .string()
    .matches(/^[\w]*$/g)
    .required(phrases.usernameEmpty)
    .min(3, phrases.usernameWrongLength)
    .max(18, phrases.usernameWrongLength),
});

type UsernameFormProps = {
  onSignUpCompleted?: (() => Promise<void>) | (() => void);
  onClose?: () => void;
  onCompleteRedirectPage?: OnCompleteRedirects;
  color?: 'dark';
  className?: string;
  rewardSoftCurrency?: boolean;
  formOrigin?: DiveModalNames;
};

const UsernameForm = ({
  onClose,
  onSignUpCompleted,
  onCompleteRedirectPage,
  color,
  className,
  rewardSoftCurrency,
  formOrigin,
}: UsernameFormProps) => {
  const client = useApolloClient();
  const router = useRouter();
  const { me, loading } = useMe();
  const meUsername = me?.username ?? '';
  const dive = useDive();
  const pathname = usePathname();
  const [username, setUsername] = useState(meUsername);
  const [randomCount, setRandomCount] = useState(1);
  const [finishRegistration] = useFinishRegistrationMutation();
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<{ username: string }>({
    shouldUseNativeValidation: true,
    defaultValues: { username },
    resolver: yupResolver(schema),
  });
  const oldErrors = useRef({} as FieldErrors);
  const [randomizeUsernames, queryResult] = useRandomizedUsernamesLazyQuery({
    onCompleted: data => {
      if (username === '' && data.randomizedUsernames) {
        const randomUsername = data.randomizedUsernames[0];
        setUsername(randomUsername);
        form.setValue('username', randomUsername);
      }
    },
  });

  useEffect(() => {
    const errors = {};
    Object.keys(form.formState.errors).forEach(key => {
      errors[key] = form.formState.errors[key];
      if (form.formState.errors[key].message !== oldErrors.current[key]?.message) {
        dive.trackErrorCreatedCustom(key, pathname, form.formState.errors[key].message, '', formOrigin);
      }
    });

    oldErrors.current = errors;
  }, [form.formState]);

  useEffect(() => {
    if (submitting || loading) {
      return;
    }

    randomizeUsernames({
      variables: { amount: 30 },
    });
  }, [loading, randomizeUsernames, submitting, username]);

  const doFinishRegistration = async (values: { username: string }) => {
    setSubmitting(true);

    try {
      const { data, errors } = await finishRegistration({
        variables: {
          payload: { username: values.username, hasAcceptedTOS: true, newsletterSignup: true, rewardSoftCurrency },
        },
      });
      const user = data?.finishRegistration;

      if (errors?.length) {
        form.setError('username', { message: getGraphqlFieldErrors(errors) });
        setSubmitting(false);
        setUsername(values.username);
        return;
      }
      const cookies = cookie.parse(document.cookie);
      if (user?.googleId) {
        gtagRecordEvent({ event: 'account_created', provider: 'google' });
        dive.trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(me?.birthdate), user });
        dive.trackUserSignedUp({
          newUserId: user.id,
          loginType: 'google',
          thirdPartyId: user.googleId,
          username: user.username,
          user,
        });
      }

      // Handle users who signed up with a wallet
      if (user?.wallets && user.wallets[0]) {
        const wallet = user?.wallets[0];
        dive.trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(me?.birthdate), user });
        dive.trackUserSignedUp({
          newUserId: user.id,
          loginType: 'wallet',
          thirdPartyId: wallet.address,
          idType: wallet.type,
          username: user.username,
          user,
        });
      }
    } catch (e) {
      form.setError('username', { message: 'Username already taken' });
    }
    setSubmitting(false);

    localStorage.removeItem('worlds_visited');

    postMessageToEngine('LOGIN_SUCCESS');

    navigateAndClose();
  };

  const handleSubmit = async (values: { username: string }) => {
    await doFinishRegistration(values);
  };

  const randomizeUsername = () => {
    if (randomCount > 28) {
      queryResult.refetch?.();
    }
    if (queryResult?.data?.randomizedUsernames) {
      form.setValue('username', queryResult.data.randomizedUsernames[randomCount]);
      setRandomCount(randomCount + 1);
      if (randomCount > 28) {
        setRandomCount(0);
      }
    }
    form.clearErrors('username');
  };

  const navigateAndClose = async () => {
    await client.resetStore();
    await onSignUpCompleted?.();

    if (onCompleteRedirectPage) {
      router.push(`user/${username}/${onCompleteRedirectPage}`);
    }

    if (typeof onClose !== 'undefined') {
      onClose();
    }
  };

  return (
    <>
      <div className={'flex flex-col items-center w-full ' + className}>
        <Heading3 className="mb-6 text-center">Pick a username</Heading3>
        <Paragraph size="medium" className="mb-6">
          Choose a username, but don&apos;t use your real name!
        </Paragraph>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="w-full">
          <section className="relative mb-5">
            <TextInput
              {...form.register('username')}
              placeholder="Don't use your real name"
              aria-label="username"
              Icon={<Shuffle />}
              onIconClick={randomizeUsername}
              color={color}
            />
            <ErrorMessage message={form.formState.errors.username?.message} className="mt-2" />
          </section>
          <ErrorMessage message={form.formState.errors.root?.message} className="mb-4" />
          <Button
            disabled={!form.formState.isValid}
            busy={form.formState.isSubmitting}
            variety="primary"
            size="medium"
            type="submit"
            fullWidth
            text="Save"
          />
        </form>
      </div>
    </>
  );
};

export default UsernameForm;
