import { forwardRef } from 'react';
import { InputHTMLAttributes } from 'react';
import { twc } from 'react-twc';

type ToggleProps = {
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const ToggleContainer = twc.label`flex m-0 p-0 flex-col h-4 w-8 bg-greyscale-veryDark rounded-2xl`;

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(({ className, ...props }: ToggleProps, ref) => {
  return (
    <ToggleContainer htmlFor={props.id} className={className}>
      <input
        className="block p-0 transition-margin duration-100 appearance-none size-4 rounded-2xl m-0 mr-8 ml-0 border-none bg-text-info checked:ml-4 checked:mr-0 checked:bg-accents-green-primary"
        {...props}
        id={props.id}
        type="checkbox"
        ref={ref}
      />
    </ToggleContainer>
  );
});
