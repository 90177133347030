import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

const ErrorContainer = styled.strong`
  color: ${props => props.theme.primaryColor.red};
`;

export type ErrorContent = Record<string, string> | string;

interface ErrorFieldProps {
  error: ErrorContent | ErrorContent[];
}

const getDisplayError = (error: ErrorContent | ErrorContent[]) => {
  let displayError = '';

  if (Array.isArray(error)) {
    const [e] = error;
    displayError = typeof e === 'object' && 'defaultMessage' in e ? e.defaultMessage : 'something went wrong';
  } else if (typeof error === 'object') {
    displayError = 'defaultMessage' in error ? error.defaultMessage : 'something went wrong';
  } else {
    displayError = error;
  }

  return displayError;
};

const isNoUserFound = (error: ErrorContent | ErrorContent[]) =>
  Array.isArray(error) && typeof error[0] === 'object' && 'id' in error[0] && error[0].id === 'auth.noUserFound';

const ErrorField = ({ error }: ErrorFieldProps) => {
  return (
    <ErrorContainer>
      {getDisplayError(error)}
      {isNoUserFound(error) && (
        <p>
          would you like to <Link href="/sign-up">Sign up?</Link>
        </p>
      )}
    </ErrorContainer>
  );
};

export default ErrorField;
