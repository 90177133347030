import { Tag, TagIdentifierInput, TagType } from 'generated/graphql';
import { Feature } from './FeatureTogglesPage.types';

export const shortenDates = (input: Partial<Feature>) => {
  const { name, enabledAt, isEnabled, disabledAt, values, criteria, createdBy, description, isConfig } = input;
  let enabledAtString: string | null = null;
  let disabledAtString: string | null = null;

  if (typeof enabledAt === 'string') {
    enabledAtString = enabledAt.substring(0, 16);
  }

  if (typeof disabledAt === 'string') {
    disabledAtString = disabledAt.substring(0, 16);
  }

  return {
    name,
    isEnabled,
    createdBy,
    description,
    isConfig,
    enabledAt: enabledAtString ?? enabledAt,
    disabledAt: disabledAtString ?? disabledAt,
    values: values?.map(shortenDates),
    tags: criteria?.tags?.map(tag => tag.name) ?? [],
    updatedAt: input.updatedAt && new Date(input.updatedAt),
    createdAt: input.createdAt && new Date(input.createdAt),
  };
};

export const noEmptyDates = input => {
  const { name, enabledAt, disabledAt, values, criteria } = input;
  return {
    name,
    criteria,
    enabledAt: enabledAt || undefined,
    disabledAt: disabledAt || undefined,
    values: values?.map(noEmptyDates),
  };
};

export const createTagsFromNames = (names = [] as string[]): TagIdentifierInput[] => {
  const tags: TagIdentifierInput[] = [];

  names.forEach(name => {
    tags.push({ type: TagType.Meta, name });
  });

  return tags;
};

export const addCriteriaObject = values => {
  const tags = createTagsFromNames(values.tags);

  return {
    ...values,
    values: values.values?.map(addCriteriaObject),
    criteria: { tags },
  };
};

export const isEnabled = (enabledAt: Date, disabledAt?: Date): boolean => {
  const currentDate = new Date();
  enabledAt = new Date(enabledAt);

  if (!disabledAt) {
    return currentDate.getTime() > enabledAt.getTime();
  }
  disabledAt = new Date(disabledAt);

  return currentDate > enabledAt && (currentDate < disabledAt || !disabledAt);
};

export const isEnabledForUser = (
  { enabledAt, disabledAt, tags }: { enabledAt?: Date; disabledAt?: Date | null; tags?: string[] },
  userTags: Pick<Tag, 'name' | 'type'>[] = []
): boolean => {
  const currentDate = new Date();
  if (!enabledAt) {
    return false;
  }

  enabledAt = new Date(enabledAt);

  if (tags && tags?.length > 0) {
    const hasTag = tags?.map(tag => {
      const hasTag = userTags?.map(userTag => userTag.name === tag && userTag.type === TagType.Meta);

      return hasTag.find(t => t === true);
    });

    if (!hasTag?.find(r => r === true)) {
      return false;
    }
  }

  if (!disabledAt) {
    return currentDate.getTime() > enabledAt.getTime();
  }

  disabledAt = new Date(disabledAt);

  return currentDate > enabledAt && (currentDate < disabledAt || !disabledAt);
};
