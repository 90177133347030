import { useCallback } from 'react';
import { useFindAllFeatureTogglesQuery } from 'generated/graphql';
import { ValueOf } from 'types/types.helpers';

type FeatureToggleModule = typeof import('constants/featureToggle.constants');
export type FeatureToggleType = ValueOf<FeatureToggleModule>;

export const useFeatureToggles = () => {
  const { data, loading, refetch } = useFindAllFeatureTogglesQuery();

  const featureToggles = data?.findAllFeatureToggles ?? [];

  const isFeatureEnabled = useCallback(
    (name: string) => !!featureToggles?.find(feature => feature.name === name)?.isEnabled,
    [featureToggles]
  );

  return { featureToggles, isFeatureEnabled, loading, refetch };
};

export const useFeatureToggle = (name: FeatureToggleType) => {
  const { featureToggles, loading, refetch } = useFeatureToggles();
  const feature = featureToggles.find(feature => feature.name === name);

  const isSubFeatureOn = (subFeatureName: string) => {
    if (!feature || !feature.isEnabled || !feature?.values || feature.values.length < 1) {
      return false;
    }

    return feature.values.some(sub => sub.name === subFeatureName && sub.isEnabled);
  };

  return { loading, refetch, isSubFeatureOn, ...feature };
};
