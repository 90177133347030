import { TwcComponentProps, twc } from 'react-twc';

type ParagraphTwcProps = TwcComponentProps<'p'> & {
  size: 'small' | 'medium' | 'large';
};

export const Paragraph = twc.p.transientProps(['size'])<ParagraphTwcProps>(({ size }) => [
  `font-medium m-0 break-words`,
  size === 'small' && 'text-p_small',
  size === 'medium' && 'text-p_medium',
  size === 'large' && 'text-p_large',
]);
