import { Search } from '@hiberworld/icons';
import { PageContentWrapper } from 'components/layouts/PageContentWrapper/PageContentWrapper';
import { ComponentProps } from 'react';
import { twc } from 'react-twc';

export const TOPBAR_HEIGHT = 56;

export const Header = twc(PageContentWrapper).attrs({ fullWidth: true, as: 'header' })`
  fixed
  gap-6
  top-0
  w-full
  backdrop-blur-[20px]
  bg-[rgba(27,28,32,0.8)]
  z-[999]
  h-topBar
  items-center
  grid
  grid-cols-[1fr_minmax(max-content,_1fr)]
  medium:grid-cols-[1fr_auto_minmax(max-content,_1fr)]
`;

const SearchIconBase = twc(Search).transientProps(['showSearch'])<{ showSearch: boolean }>(({ showSearch }) => [
  showSearch ? 'text-greyscale-white' : 'text-greyscale-lightGrey',
]);

export const SearchIcon = twc(SearchIconBase)<
  ComponentProps<typeof Search> & ComponentProps<typeof SearchIconBase>
>`medium:hidden`;
