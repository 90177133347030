import React from 'react';
import { ForgotPasswordDialogProps } from './AuthDialog.types';
import Head from 'next/head';
import ForgotPasswordForm from 'views/forms/ForgotPasswordForm/ForgotPasswordForm';
import { phrases } from 'constants/phrases';
import { Arrow } from '@hiberworld/icons';

export const ForgotPasswordDialog = ({ navigateToLogin }: ForgotPasswordDialogProps) => {
  return (
    <>
      <Head>
        <title>
          {phrases.hiberWorld} - {phrases.forgotPasswordModal.title}
        </title>
      </Head>

      <Arrow size={36} className="absolute top-4 left-4 cursor-pointer" color="#FFFFFF" onClick={navigateToLogin} />
      <div className="flex flex-col items-center pt-24 landscape:max-mediumLarge:pt-0 small:px-7">
        <ForgotPasswordForm navigateToLogin={navigateToLogin} color="dark" />
      </div>
    </>
  );
};
