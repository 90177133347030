import React from 'react';
import { GameLoader } from './GameLoader';
import { FeedLoaderProps } from './Skeleton.types';
import { SkeletonLoader } from './SkeletonLoader';

const mobileWidth = 'calc(50% - 10px / 2)';
const tabletWidth = 'calc(33% - 14px / 3)';
const desktopWidth = 'calc(25% - 45px / 4)';

const mobileHeight = 'calc(27vw - 6px)';
const tabletHeight = 'calc(18vw - 6px)';
const desktopHeight = 'calc(11vw + 24px)';

export const FeedLoader: React.FC<FeedLoaderProps> = ({ quantity, showAuthor, showTitle = true }) => {
  const numberOfCards = [...Array(quantity)];

  return (
    <SkeletonLoader maxWidth="1408px">
      {numberOfCards.map(i => (
        <GameLoader
          key={`${Math.random()}${i}`}
          showAuthor={showAuthor}
          showTitle={showTitle}
          width={mobileWidth}
          tabletWidth={tabletWidth}
          desktopWidth={desktopWidth}
          height={mobileHeight}
          tabletHeight={tabletHeight}
          desktopHeight={desktopHeight}
        />
      ))}
    </SkeletonLoader>
  );
};
