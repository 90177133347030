import React from 'react';

export type HiberCookies = {
  rid?: string;
  accessToken?: string;
} & Record<string, string>;

type CookieContextType = {
  setCookie: (name: string, val: string, maxAge?: number) => void;
  removeCookie: (name: string) => void;
  cookies: HiberCookies;
};

export const CookieContext = React.createContext<CookieContextType>({
  setCookie: () => {},
  removeCookie: () => {},
  cookies: { rid: '' },
});
