/**
 * Used to get the slug used to query Page data from CMS
 * @param route
 * @returns
 */
export const getPageSlugFromRoute = (route: string): string => {
  if (route === '/') {
    return '/';
  }

  const slugs = route.split('/');

  if (slugs[1] === 'feed' && slugs.length >= 3) {
    return slugs[2];
  }

  return slugs[1];
};
