import { useApolloClient } from '@apollo/client';
import { useNotificationsAmountQuery } from 'generated/graphql';
import { useMe } from 'hooks/useMe';

export const useNotificationsAmount = (baseOptions: Parameters<typeof useNotificationsAmountQuery>[0] = {}) => {
  const me = useMe();
  const { data, loading, error, refetch } = useNotificationsAmountQuery({
    pollInterval: 15000,
    skip: !me.isLoggedIn,
    ...baseOptions,
  });

  const client = useApolloClient();

  const setNotificationsAmountToZero = () => {
    client.cache.modify({
      fields: {
        notificationsAmount: () => 0,
      },
    });
  };

  return { setNotificationsAmountToZero, amount: data?.notificationsAmount || 0, loading, error, refetch };
};
