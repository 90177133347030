import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/common/buttons/Button';
import { OnLoadParams, Recaptcha } from 'components/globals/Recaptcha';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import { Spacer } from 'components/layouts/Spacer';
import { ErrorMessage, Heading4, Paragraph } from 'components/typography';
import { phrases } from 'constants/phrases';
import { useResetPasswordRequestMutation } from 'generated/graphql';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getGraphqlFieldErrors } from 'utils/getGraphqlFieldError';
import * as yup from 'yup';

const schema = yup.object({ emailOrUsername: yup.string().required(phrases.usernameOrEmailEmpty) });

type ForgotPasswordState = {
  emailOrUsername: string;
};

type ForgotPasswordFormProps = {
  navigateToLogin: () => void;
  color?: 'dark';
};

export const ForgotPasswordForm = ({ navigateToLogin, color }: ForgotPasswordFormProps) => {
  const [sentEmail, setSentEmail] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [captcha, setCaptcha] = useState<OnLoadParams>({ response: '', recaptchaElement: null });
  const [resetPasswordRequest, { loading }] = useResetPasswordRequestMutation({
    onError: error => {
      setRequestError(error.message);
    },
  });

  const { handleSubmit, register, formState } = useForm<ForgotPasswordState>({
    defaultValues: {
      emailOrUsername: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: ForgotPasswordState) => {
    captcha?.recaptchaElement?.execute();

    const res = await resetPasswordRequest({
      variables: {
        email: values.emailOrUsername,
        captcha: captcha.response ?? '',
      },
    });

    if (res.errors) {
      const errorMessage = getGraphqlFieldErrors(res.errors);
      setRequestError(errorMessage);
      return;
    }

    if (!res.data?.resetPasswordRequest) {
      setRequestError('Could not find user');
      return;
    }

    setSentEmail(true);
  };

  if (sentEmail) {
    return (
      <>
        <div className="flex items-center ">
          <Heading4 className="mb-4 text-center">{phrases.forgotPasswordModal.emailSent.title}</Heading4>
        </div>
        <Spacer height={16} />
        <Paragraph size="medium">{phrases.forgotPasswordModal.emailSent.disclaimer}</Paragraph>
        <Spacer height={16} />
        <div className="flex justify-center">
          <Button variety="primary" size="medium" text="OK" onClick={navigateToLogin} />
        </div>
      </>
    );
  }

  return (
    <div className="flex h-full flex-col items-center">
      <Recaptcha onLoad={setCaptcha} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading4 className="mb-6 text-center">{phrases.forgotPasswordModal.title}</Heading4>
        <TextInput
          {...register('emailOrUsername')}
          placeholder={phrases.forgotPasswordModal.emailOrUsername}
          aria-label="email or username"
          className="mb-4"
          color={color}
        />
        <ErrorMessage message={formState.errors.emailOrUsername?.message} className="mb-2" />
        <Paragraph size="small">{phrases.forgotPasswordModal.disclaimer}</Paragraph>
        <div className="flex justify-center items-center my-4">
          <Button variety="primary" size="medium" type="submit" disabled={loading} text="Confirm" fullWidth />
        </div>
        <ErrorMessage message={requestError} />
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
