import theme from 'style/theme';
import styled, { css } from 'styled-components';
import { CircularImageStyleProps } from './CircularImage.types';

const getResponsiveSize = (size: CircularImageStyleProps['size']) => {
  if (typeof size === 'number') {
    return css`
      width: ${size}px;
      height: ${size}px;
    `;
  }
  return Object.entries(size).map(([breakpoint, value]) => {
    if (breakpoint === 'default') {
      return css`
        width: ${value}px;
        height: ${value}px;
      `;
    }
    return css`
      @media screen and (min-width: ${theme.breakpoint[breakpoint]}) {
        width: ${value}px;
        height: ${value}px;
      }
    `;
  });
};

export const CircularImage = styled.img<CircularImageStyleProps>`
  border-radius: 50%;
  display: block;
  ${({ size }) => getResponsiveSize(size)};
`;
