import { Close, Menu } from '@hiberworld/icons';

type HamburgerButtonProps = {
  expanded: boolean;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  showNotificationIndicator?: boolean;
};

export const HamburgerButton = ({ expanded, onClick, showNotificationIndicator }: HamburgerButtonProps) => (
  <div className="relative cursor-pointer flex" title="Expand Menu" data-cy="topBarHamburgerMenu" onClick={onClick}>
    {expanded ? (
      <Close className="text-greyscale-white" role="button" size={40} title="Close Menu" />
    ) : (
      <Menu
        className="text-greyscale-lightGrey hover:text-greyscale-white duration-fast"
        size={40}
        role="button"
        title="Open Menu"
      />
    )}
    {!expanded && showNotificationIndicator ? (
      <div
        className="flex absolute right-1 w-[10px] h-[10px] bg-primary-main rounded-lg"
        data-testid="showNotificationIndicator"
      />
    ) : null}
  </div>
);
