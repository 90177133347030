import { TwcComponentProps, twc } from 'react-twc';

type LabelTwcProps = TwcComponentProps<'label'> & {
  size: 'small' | 'specialSmall' | 'medium' | 'large' | 'xlarge';
};

export const Label = twc.label.transientProps(['size'])<LabelTwcProps>(({ size }) => [
  size === 'small' && 'text-label_small font-medium',
  size === 'specialSmall' && 'text-label_specialSmall uppercase font-bold',
  size === 'medium' && 'text-label_medium font-medium',
  size === 'large' && 'text-label_large font-medium',
  size === 'xlarge' && 'text-label_xlarge font-medium',
]);
