import { Close } from '@hiberworld/icons';
import { Card } from 'components/cards/Card/Card';
import { waitForAnimationsToComplete } from 'hooks/useDialog';
import { DialogHTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { twc } from 'react-twc';

const DialogStyle = twc.dialog`
  fixed m-auto block inset-0 p-0 min-w-[min(90vw,440px)] rounded-lg border-greyscale-dark border-0 bg-dialog-background transition-transform duration-fast ease-in-out shadow-[0_8px_16px_0_rgba(27,28,32,0.2)]
  pointer-events-none opacity-0 scale-[0.001]
  open:scale-100 open:opacity-100 open:pointer-events-auto
  backdrop:bg-dialog-backdrop backdrop:backdrop-blur-[1px] backdrop:animate-[dialogFadeIn_0.1s_ease-out]
`;

const Inner = twc(Card)`
bg-opacity-100 flex-col
`;

const CloseButton = twc.button`
 absolute top-4 right-4 bg-transparent p-0 border-none text-text-default text-[1rem] hover:cursor-pointer`;

export type DialogProps = {
  id: string;
  onClose?: () => void;
  children?: ReactNode;
  stayOpenOnOutsideClick?: boolean;
  hideClose?: boolean;
} & DialogHTMLAttributes<HTMLDialogElement>;

export const Dialog = ({ id, onClose, children, stayOpenOnOutsideClick, hideClose, ...props }: DialogProps) => {
  const ref = useRef<HTMLDialogElement>(null);
  const clickedOutside = useRef(false);

  const close = async () => {
    onClose?.();
    await waitForAnimationsToComplete(ref.current as Element);
    ref.current?.setAttribute('inert', '');
    ref.current?.close();
  };

  const backdropClick = (event: MouseEvent) => {
    const dialog = event.target as HTMLDialogElement;
    clickedOutside.current = dialog.nodeName === 'DIALOG' ? true : false;
  };

  const backdropRelease = (event: MouseEvent) => {
    const dialog = event.target as HTMLDialogElement;
    if (dialog.nodeName === 'DIALOG' && clickedOutside.current === true) {
      close();
    }
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (stayOpenOnOutsideClick) {
      return;
    }

    ref.current.addEventListener('mousedown', backdropClick);
    ref.current.addEventListener('mouseup', backdropRelease);

    return () => {
      ref.current?.removeEventListener('mousedown', backdropClick);
      ref.current?.removeEventListener('mouseup', backdropRelease);
    };
  }, [ref.current, backdropClick, stayOpenOnOutsideClick]);

  return (
    <DialogStyle id={id} ref={ref} {...props}>
      <Inner>
        {children}
        {!hideClose && (
          <CloseButton>
            <Close size={24} onClick={close} title="Close modal" />
          </CloseButton>
        )}
      </Inner>
    </DialogStyle>
  );
};
