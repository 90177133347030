import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isTouch } from 'utils/isTouch';

type LastEventType = 'touch' | 'mouse';

export type LastEventContextType = {
  event: LastEventType;
};
const LastEventContext = React.createContext<LastEventContextType>({
  event: 'touch',
});

export const LastEventProvider = ({ children }) => {
  const [event, setEvent] = useState<LastEventType>(isTouch() ? 'touch' : 'mouse');

  const handleMouseUp = () => {
    setEvent('mouse');
  };

  const handleTouchEnd = () => {
    setEvent('touch');
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    document.addEventListener('click', handleMouseUp);

    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('click', handleMouseUp);

      document.removeEventListener('touchend', handleTouchEnd);
    };
  });

  const ctxValue = useMemo(
    () => ({
      event,
    }),
    [event]
  );

  return <LastEventContext.Provider value={ctxValue}>{children}</LastEventContext.Provider>;
};

export const useLastEvent = () => {
  const { event } = useContext(LastEventContext);

  return { event };
};
