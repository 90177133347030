import { useSearchParam } from './useSearchParam';

export const engineUrls = {
  hiberEngineProd: 'https://hiber.hiberworld.com/',
  hiberEngineDev: 'https://dao-pr.dev.hiberdev.net/',
} as const;

export const isEmbeddedInEngine = (eventSource: string): boolean => {
  if (eventSource === 'engine') {
    return true;
  }

  if (typeof window === 'undefined') {
    return false;
  }

  const isInIframe = window?.location !== window?.parent?.location;

  if (!isInIframe) {
    return false;
  }

  const parentUrl = isInIframe ? window.document.referrer : null;

  if (!parentUrl) {
    return false;
  }

  const domainList: string[] = Object.values(engineUrls);

  return domainList.includes(parentUrl);
};

export const useIsEmbeddedInEngine = () => {
  const eventSource = useSearchParam('eventSource');

  return { isEmbeddedInEngine: () => isEmbeddedInEngine(eventSource) };
};
