import { Add } from '@hiberworld/icons';
import { Link } from 'components/common/Link';
import { CollapsibleTextButton } from 'components/common/buttons/CollapsibleTextButton';
import { Spacer } from 'components/layouts/Spacer';
import { SoftCurrencyLogo } from 'components/logos/SoftCurrencyLogo';
import { useDive } from 'hooks/useDive';
import { useIsSuspended } from 'hooks/useIsSuspended/useIsSuspended';
import { useMe } from 'hooks/useMe';
import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import { useState } from 'react';
import { twc } from 'react-twc';
import { NotificationsFeed } from 'views/feeds/NotificationsFeed/NotificationsFeed';
import { HamburgerMenu } from 'views/global/HamburgerMenu/HamburgerMenu';
import { HiberLogo } from 'views/global/TopBar/HiberLogo';
import { SearchBar } from 'views/global/TopBar/SearchBar';
import { UserInfo } from 'views/global/TopBar/UserInfo';
import { SoftCurrencyDisplay } from './SoftCurrencyDisplay';
import * as S from './TopBar.styles';
import { TOPBAR_HEIGHT } from './TopBar.styles';
import { useAuthDialog } from 'components/common/AuthDialog/useAuthDialog';
import { phrases } from 'constants/phrases';

const SoftCurrency = dynamic(
  () => import('components/logos/SoftCurrencyLogoAnimated').then(m => m.SoftCurrencyLogoAnimated),
  {
    ssr: false,
    loading: () => <SoftCurrencyLogo size={24} />,
  }
);

const RightSide = twc.div`flex justify-end items-center gap-1 medium:gap-6`;

const SoftCurrencyLink = twc(Link)`
  transition-all duration-200 hover:saturate-[120%] hover:brightness-[120%]
`;
export const TopBar = () => {
  const [showSearch, setShowSearch] = useState(false);
  const { isLoggedIn, me } = useMe();
  const router = useRouter();
  const pathname = usePathname() ?? '';
  const isSuspended = useIsSuspended();
  const dive = useDive();
  const authDialog = useAuthDialog();

  const toggleSearch = () => {
    setShowSearch(s => !s);
  };

  const navigateToCreate = () => {
    if (!isLoggedIn) {
      authDialog.open(
        'SIGN_UP',
        {
          name: 'sign_up',
          description: phrases.authDialog.signUp.createAccount,
          origin: 'top_bar',
        },
        { signUpTitle: phrases.authDialog.signUp.createAccount }
      );
      return;
    }
    router.push('/create');
  };

  const sendDiveTracking = () => {
    dive.track('userInteract', {
      page_id: pathname,
      page_id_before: '',
      type: 'button',
      name: 'shop',
    });
  };

  const hasPublishedProject = Boolean(me?.totalPublishedGames);

  return (
    <>
      <Spacer height={TOPBAR_HEIGHT} />
      <S.Header>
        <div className="flex">
          <HiberLogo data-cy="HiberworldLogo" />
        </div>
        {isLoggedIn ? <SearchBar isOpen={showSearch} /> : <div />}
        <RightSide>
          {isLoggedIn && (
            <S.SearchIcon showSearch={showSearch} size="42px" role="button" title="search" onClick={toggleSearch} />
          )}
          <CollapsibleTextButton
            variety="primary"
            size="medium"
            Icon={<Add title="Create World" size={20} />}
            title="Create world"
            text="CREATE WORLD"
            fullRound
            collapsed={hasPublishedProject}
            disabled={Boolean(isSuspended)}
            onClick={navigateToCreate}
          />
          {isLoggedIn && me && (
            <>
              <NotificationsFeed />
              <SoftCurrencyLink href={'/shop?filter=All'} onClick={sendDiveTracking}>
                <span
                  className="flex gap-[6px] items-center flex-1"
                  title="HiBux"
                  style={{ height: `${TOPBAR_HEIGHT}px` }}>
                  <SoftCurrency size={24} />
                  <SoftCurrencyDisplay currency={me.softCurrency}></SoftCurrencyDisplay>
                </span>
              </SoftCurrencyLink>
              <UserInfo
                pictureUrl={me.pictureUrl}
                level={me?.level ?? 1}
                username={me.username}
                progressToNextLevel={(me.currentXp ?? 1) / (me.xpToNextLevel ?? 1)}
              />
            </>
          )}
          <HamburgerMenu />
        </RightSide>
      </S.Header>
    </>
  );
};
