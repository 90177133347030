"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
exports.theme = {
    typography: {
        header: {
            h1: {
                font: {
                    app: 'Inter-Bold',
                    web: 'Inter',
                },
                size: {
                    app: '55px',
                    web: 'clamp(44px, 1.9779rem + 2.5735vw, 58px)',
                },
                lineHeight: '1.02',
                weight: '700',
                style: 'normal',
            },
            h2: {
                font: {
                    app: 'Inter-Bold',
                    web: 'Inter',
                },
                size: {
                    app: '32px',
                    web: 'clamp(32px, 1.3382rem + 2.2059vw, 44px)',
                },
                lineHeight: '1.25',
                weight: '700',
                style: 'normal',
            },
            h3: {
                font: {
                    app: 'Inter-Bold',
                    web: 'Inter',
                },
                size: {
                    app: '26px',
                    web: 'clamp(26px, 1.2941rem + 1.1029vw, 32px)',
                },
                lineHeight: '1.23',
                weight: '700',
                style: 'normal',
            },
            h4: {
                font: {
                    app: 'Inter-Bold',
                    web: 'Inter',
                },
                size: {
                    app: '20px',
                    web: 'clamp(22px, 1.2647rem + 0.3676vw, 24px)',
                },
                lineHeight: '1.2',
                weight: '700',
                style: 'normal',
            },
            h5: {
                font: {
                    app: 'Inter-Bold',
                    web: 'Inter',
                },
                size: {
                    app: '16px',
                    web: '18px',
                },
                lineHeight: '1.25',
                weight: '700',
                style: 'normal',
            },
            h6: {
                font: {
                    app: 'Inter-Bold',
                    web: 'Inter',
                },
                size: {
                    app: '14px',
                    web: '14px',
                },
                lineHeight: '1.14',
                weight: '700',
                style: 'normal',
            },
        },
        button: {
            small: {
                font: {
                    app: 'Inter-SemiBold',
                    web: 'Inter',
                },
                size: '12px',
                lineHeight: '1',
                weight: '600',
                style: 'normal',
            },
            medium: {
                font: {
                    app: 'Inter-SemiBold',
                    web: 'Inter',
                },
                size: '14px',
                lineHeight: '1',
                weight: '600',
                style: 'normal',
            },
            large: {
                font: {
                    app: 'Inter-SemiBold',
                    web: 'Inter',
                },
                size: '16px',
                lineHeight: '1',
                weight: '600',
                style: 'normal',
            },
        },
        label: {
            small: {
                font: {
                    app: 'Inter-Medium',
                    web: 'Inter',
                },
                transform: 'none',
                size: '10px',
                lineHeight: '1.6',
                weight: '500',
                style: 'normal',
            },
            specialSmall: {
                font: {
                    app: 'Inter-Bold',
                    web: 'Inter',
                },
                transform: 'uppercase',
                size: '11px',
                lineHeight: '1',
                weight: '700',
                style: 'normal',
            },
            medium: {
                font: {
                    app: 'Inter-Medium',
                    web: 'Inter',
                },
                transform: 'none',
                size: '12px',
                lineHeight: '1.33',
                weight: '500',
                style: 'normal',
            },
            large: {
                font: {
                    app: 'Inter-Medium',
                    web: 'Inter',
                },
                transform: 'none',
                size: '14px',
                lineHeight: '1.14',
                weight: '500',
                style: 'normal',
            },
            xlarge: {
                font: {
                    app: 'Inter-Medium',
                    web: 'Inter',
                },
                transform: 'none',
                size: '16px',
                lineHeight: '1.25',
                weight: '500',
                style: 'normal',
            },
        },
        body: {
            small: {
                font: {
                    app: 'Inter-Medium',
                    web: 'Inter',
                },
                size: {
                    web: '14px',
                    app: '14px',
                },
                lineHeight: '1.57',
                weight: '500',
                style: 'normal',
            },
            medium: {
                font: {
                    app: 'Inter-Medium',
                    web: 'Inter',
                },
                size: {
                    web: '16px',
                    app: '16px',
                },
                lineHeight: '1.5',
                weight: '500',
                style: 'normal',
            },
            large: {
                font: {
                    app: 'Inter-Medium',
                    web: 'Inter',
                },
                lineHeight: '1.5',
                size: {
                    web: 'clamp(18px, 1.0147rem + 0.3676vw, 20px)',
                    app: '20px',
                },
                weight: '500',
                style: 'normal',
            },
        },
    },
};
