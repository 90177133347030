import { Link } from 'components/common/Link';
import { CircularImage } from 'components/common/images/CircularImage';
import { Heading6, Label } from 'components/typography';
import { twc } from 'react-twc';
import styled, { useTheme } from 'styled-components';

export type UserInfoProps = {
  level: number;
  username: string;
  pictureUrl: string;
  progressToNextLevel: number;
};

const Circle = styled.circle<{ offset: number; circumference: number }>`
  animation: slideIn 2s ease-out;
  animation-fill-mode: forwards;

  @keyframes slideIn {
    0% {
      stroke-dashoffset: ${({ circumference }) => circumference};
    }
    100% {
      stroke-dashoffset: ${({ offset }) => offset};
    }
  }
`;

const HideOnMobile = twc.div`
  hidden breakMobile:block transition-all duration-200 ease-out
  hover:saturate-150 hover:brightness-[1.2]
`;

export const CircluarProgressBar = ({
  progress,
  size,
  pictureUrl,
}: {
  progress: number;
  size: number;
  pictureUrl: string;
}) => {
  const theme = useTheme();
  const strokeWidth = 3;
  const radius = size / 2 - strokeWidth;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference * ((100 - progress) / 100);
  const imageMargin = 3;
  const imageSize = size - strokeWidth * 2 - imageMargin * 2;

  return (
    <div className="flex relative items-center justify-center">
      <svg width={size} height={size} style={{ transform: 'rotate(-90deg)', display: 'block' }}>
        <linearGradient id="linear" x1="100%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#BD3FD1" />
          <stop offset="100%" stopColor="#814CC7" />
        </linearGradient>
        <circle
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={strokeWidth}
          fill="none"
          stroke={theme.colorTheme.greyscale.dark}
        />
        <Circle
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={strokeWidth}
          fill="none"
          stroke="url(#linear)"
          strokeLinecap="round"
          offset={offset}
          circumference={circumference}
          strokeDasharray={circumference}
        />
      </svg>
      <div className="absolute">
        <CircularImage src={pictureUrl} size={imageSize} alt="See your progress" />
      </div>
    </div>
  );
};

export const UserInfo = ({ pictureUrl, username, level, progressToNextLevel }: UserInfoProps) => {
  return (
    <HideOnMobile>
      <Link href={`/user/${username}/`}>
        <div className="flex flex-row gap-2 items-center" data-testid="reward-link">
          <CircluarProgressBar progress={progressToNextLevel * 100} size={40} pictureUrl={pictureUrl} />
          <div className="flex flex-col gap-[0.11rem]">
            <Heading6>{username}</Heading6>
            <Label size="specialSmall" className="text-greyscale-lightGrey">
              Level {level}
            </Label>
          </div>
        </div>
      </Link>
    </HideOnMobile>
  );
};
