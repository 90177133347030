import { LoginInput, useSigninMutation } from 'generated/graphql';

export const useSignin = () => {
  const [signinMutation, signinData] = useSigninMutation();

  const signin = (variables: LoginInput) => {
    return signinMutation({
      variables: { ...variables },
    });
  };

  return { signin, loading: signinData.loading };
};
