import { Spinner } from 'components/common/spinners/Spinner';
import isNil from 'lodash/isNil';
import React, { forwardRef } from 'react';
import { TextInputProps } from './SearchInput.types';

export const SearchField = forwardRef<HTMLInputElement, TextInputProps>(
  ({ Icon, placeholder, value = '', setValue, onEnter, onFocus, autoFocus, limit, loading = false }, ref) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onEnter?.(value);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isNil(limit) && e.target.value.length > limit) {
        return;
      }
      setValue?.(e.target.value);
    };

    const handleSearch = () => {
      onEnter?.(value);
    };

    const hasValue = value.trim() !== '';

    return (
      <div className="relative h-10">
        <input
          className="w-full peer h-10 outline-none border border-solid border-greyscale-dark rounded-lg pl-4 pr-8 
          transition-colors duration-fast ease-in-out
          bg-greyscale-almostBlack/40 text-greyscale-white focus:border-greyscale-mediumGrey"
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          autoFocus={autoFocus}
          data-testid="TextInput"
          value={value}
          ref={ref}
          aria-label="Search"></input>
        {hasValue && loading && (
          <div className="flex absolute right-0 top-0 w-8 h-full items-center justify-center">
            <Spinner tiny white />
          </div>
        )}
        {!loading && (
          <div
            data-hasvalue={hasValue}
            className="flex absolute right-0 top-0 w-8 h-full items-center justify-center cursor-pointer 
            transition-colors duration-fast ease-in-out text-greyscale-mediumGrey peer-focus:text-greyscale-veryLightGrey"
            onClick={handleSearch}>
            {Icon}
          </div>
        )}
      </div>
    );
  }
);
SearchField.displayName = 'TextInput';
