import { FriendRequestAmountDocument, FriendRequestAmountQuery, useFriendRequestAmountQuery } from 'generated/graphql';
import { useMe } from './useMe';
import { ApolloCache } from '@apollo/client';
import { NormalizedCache } from 'apollo-cache-inmemory';

export const useAmountOfFriendRequests = () => {
  const { isLoggedIn } = useMe();
  const friendRequestAmount = useFriendRequestAmountQuery({ skip: !isLoggedIn });
  return friendRequestAmount.data?.friendRequestAmount || 0;
};

export const updateFriendRequestAmountInCache = (store: ApolloCache<NormalizedCache>) => {
  const friendRequestAmount = store.readQuery<FriendRequestAmountQuery>({ query: FriendRequestAmountDocument });

  if (friendRequestAmount?.friendRequestAmount) {
    store.writeQuery<FriendRequestAmountQuery>({
      query: FriendRequestAmountDocument,
      data: { __typename: 'Query', friendRequestAmount: friendRequestAmount.friendRequestAmount - 1 },
    });
  }
};
