import React from 'react';
import * as S from './Skeleton.styles';
import { TextLoaderProps } from './Skeleton.types';

export const TextLoader: React.FC<TextLoaderProps> = ({ twoRows }) => {
  return (
    <S.TextWrapper>
      <S.FirstTextPlaceholder />
      {twoRows && <S.SecondTextPlaceholder />}
    </S.TextWrapper>
  );
};
