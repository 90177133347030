import { useEffect, useRef } from 'react';
import { isClientSide } from 'utils/isClientSide';

export const usePressOutside = ({ callback }: { callback: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isClientSide()) {
      return;
    }
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref.current]);

  return ref;
};
