import { useParams, useSearchParams } from 'next/navigation';

export type SearchParam =
  | 'hideNavigation'
  | 'hideChat'
  | 'hideOnlineFriends'
  | 'eventSource'
  | 'embed'
  | 'type'
  | 'paymentIntentId'
  | 'checkoutId'
  | 'defaultAvatar'
  | 'hideMobileLogin'
  | 'worldId'
  | 'startingPrefabId'
  | 'autoPlay'
  | 'username'
  | 'initialFilter';

export type Param = 'username' | 'id' | 'slug';

export const useSearchParam = (param: SearchParam) => {
  const params = useSearchParams();

  return params?.get(param) ?? '';
};

export const useParam = (param: Param): string => {
  const params = useParams();

  const value = params?.[param] ?? '';

  if (typeof value === 'string') {
    return value;
  }
  return value[0];
};
