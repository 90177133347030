import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { config } from 'config';
import { GraphQLError } from 'graphql';
import fetch from 'isomorphic-unfetch';
import { typePolicies } from 'lib/typePolicies';
import { getGraphqlFieldErrors } from 'utils/getGraphqlFieldError';
import { isClientSide } from 'utils/isClientSide';
import { refreshAccessTokenIfExpired } from './auth';

let apolloClient: null | ReturnType<typeof createApolloClient> = null;

// Polyfill fetch() on the server (used by apollo-client)
if (typeof window === 'undefined') {
  global.fetch = fetch;
}

type CreateApolloOptions = { getCookies: () => Record<string, string> };

export function createApolloClient(initialState: NormalizedCacheObject, { getCookies }: CreateApolloOptions) {
  const options: BatchHttpLink.Options = {
    uri: `${config.settings.apiUrl}/gql`, // Server URL (must be absolute)
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  };

  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach((error: GraphQLError & { code: number; serviceName: string }) => {
        if (error?.extensions) {
          const { response } = error.extensions as {
            response: { url: string; body: { errors?: Error[]; message: string } };
          };
          console.error(
            `[Query Error] query "${operation?.operationName}" failed. ${error?.message}\n`,
            `Response from ${response?.url}\n`,
            `"${response?.body?.errors?.[0]?.message || response?.body?.message}"\n`,
            `path: ${error.path}`,
            `URL: ${options.uri}`
          );
        } else {
          console.error(
            `[Query Error] ${error?.code} from ${error?.serviceName}\n`,
            `Query "${operation?.operationName}" failed. ${error?.message}`,
            error.message === 'validation.error' ? `: ${getGraphqlFieldErrors([error])}` : '',
            `\npath: ${error.path}`,
            `URL: ${options.uri}`
          );
        }
      });
  });

  const httpLink = new HttpLink({
    ...options,
    async fetch(input, init) {
      const controller = new AbortController();

      let timeoutId: NodeJS.Timeout | null = null;

      if (!isClientSide()) {
        timeoutId = setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const parsed: any = JSON.parse((init?.body as string) || '');
          // eslint-disable-next-line no-console
          console.log(
            '[Aborting SSR request due to 10s timeout]',
            JSON.stringify({ operation: parsed.operationName, variables: parsed.variables })
          );
          controller.abort();
        }, 10 * 1000);
      }

      return fetch(input, { ...init, signal: controller.signal }).finally(() => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      });
    },
  });

  const authLink = setContext(async (_, { headers: defaultHeaders }) => {
    // Fetch token from cookie store
    const cookies = getCookies();

    let accessToken: string | null = cookies.accessToken;

    // Headers set in CloudFront Functions which we forward to the api
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _ab } = cookies;

    // Refresh token if token has expired
    if (isClientSide()) {
      // only refresh the access token in the link on the client side
      // for server side we do that in `_app.getInitialProps()`
      // this is because if we refresh it here on the server side aswell we will refresh it for every request that is made since we can't store the new token anywhere
      accessToken = await refreshAccessTokenIfExpired();
    }

    // Handle headers
    const headers = {
      ...defaultHeaders,
    };

    if (_ab) {
      headers.ab = _ab;
    }

    // Add auth header if access token exists
    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`;
    }

    if (cookies.rid) {
      headers.trck = cookies.rid;
    }

    return {
      headers,
    };
  });

  const cache = new InMemoryCache({
    typePolicies,
    possibleTypes: {
      ShopItem: ['RpmShopItem', 'EmoteShopItem', 'GameAssetShopItem'],
    },
  }).restore(initialState || {});

  return new ApolloClient({
    connectToDevTools: isClientSide(),
    ssrMode: !isClientSide(), // Disables forceFetch on the server (so queries are only run once)
    ssrForceFetchDelay: 100,
    // !IMPORTANT!
    // the "httpLink" needs to be last since it's a terminating link

    link: from([authLink, errorLink, httpLink]),
    cache,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  });
}

interface GetApolloInput {
  initialState?: NormalizedCacheObject;
  options?: CreateApolloOptions;
}

export const getApolloClient = (args?: GetApolloInput) => {
  const {
    initialState = {},
    options = {
      getCookies: () => {
        return {};
      },
    },
  } = args || {};
  /*
   * Make sure to create a new client for every server-side request
   * so that data isn't shared between connections (which would be bad)
   */
  if (!isClientSide()) {
    return createApolloClient(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState, options);
  }

  return apolloClient;
};
