import { getSize } from 'components/common/SkeletonLoader';
import styled from 'styled-components';
import theme from 'style/theme';
import { BreakpointSizeMap, Size, SpacerStyleProps } from './Spacer.types';

type Direction = 'width' | 'height';

const sizeStyle = (direction: Direction, size: Size) => `${direction}: ${getSize(size)};`;

const getSpacerStyle = (direction: Direction, size: Size | BreakpointSizeMap) => {
  if (typeof size !== 'object') {
    return sizeStyle(direction, size);
  }

  return Object.entries(size)
    .map(([breakpoint, value]) => {
      if (breakpoint === 'default') {
        return sizeStyle(direction, value);
      }
      return `
      @media screen and (min-width: ${theme.breakpoint[breakpoint]}) {
        ${sizeStyle(direction, value)};
      }`;
    })
    .join('');
};

export const Spacer = styled.div<SpacerStyleProps>`
  ${({ width, height, backgroundColor }) =>
    `${getSpacerStyle('width', width ?? 0)};
    ${getSpacerStyle('height', height ?? 0)};
    background-color: ${backgroundColor ?? theme.colorTheme.greyscale.dark};
  `}
`;
