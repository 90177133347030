import { Shuffle } from '@hiberworld/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/common/buttons/Button';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import { Label } from 'components/typography';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import cookie from 'cookie';
import {
  SignUpWithEmailMutation,
  SignUpWithEmailMutationResult,
  UserType,
  useRandomizedUsernamesQuery,
} from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import { gtagRecordEvent } from 'lib/gtag';
import { useSignUpWithEmail } from 'lib/useSignUpWithEmail';
import { useSubmitForm } from 'lib/useSubmitForm';
import { useEffect, useRef, useState } from 'react';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { isOverAge } from 'utils/validateAge';
import BirthDaySelect from 'views/forms/fields/BirthdaySelect';
import ErrorField from 'views/forms/fields/ErrorField';
import { schema } from './CreateAccountForm.schema';
import { CreateAccountFormData, CreateAccountFormProps } from './CreateAccountForm.types';
import { usePathname } from 'next/navigation';
import { FieldErrors } from 'react-hook-form';

const CreateAccountForm = ({
  onRegisterSuccess,
  busy,
  rewardSoftCurrency,
  className,
  color,
  formOrigin,
  hideBirthdaySelectIcon,
}: CreateAccountFormProps) => {
  const { signUpWithEmail } = useSignUpWithEmail();
  const pathname = usePathname() ?? '';
  const { track, trackUserCreated, trackUserSignedUp, trackErrorCreatedCustom } = useDive();
  const { me } = useMe();
  const [randomCount, setRandomCount] = useState(0);
  const {
    submitGqlForm,
    loading: submitting,
    form,
  } = useSubmitForm<CreateAccountFormData>({
    mode: 'onSubmit',
    shouldUseNativeValidation: true,
    defaultValues: {
      username: me?.username ?? '',
      email: '',
      password: '',
      day: '',
      month: '',
      year: '',
      birthdate: '',
      type: UserType.Registered,
    },
    resolver: yupResolver(schema),
  });

  const { setValue, handleSubmit, register, formState } = form;
  const birthdateValue = form.getValues().birthdate;
  const oldErrors = useRef<FieldErrors<CreateAccountFormData>>({});

  const { data, fetchMore } = useRandomizedUsernamesQuery({
    variables: { amount: 30 },
    onCompleted: data => {
      if (data.randomizedUsernames && data.randomizedUsernames?.length > 0) {
        setValue('username', data.randomizedUsernames[0]);
      }
    },
  });

  useEffect(() => {
    if (!(form.getValues().day && form.getValues().month && form.getValues().year)) {
      return;
    }

    if (birthdateValue) {
      const birthdate = new Date(birthdateValue);
      track('fieldEntered', {
        value: birthdate.toLocaleDateString('sv-SE'),
        page_id: pathname,
        name: 'birthdate',
        id: formOrigin,
      });
    }
  }, [birthdateValue, track, pathname]);

  useEffect(() => {
    const errors = {};
    Object.keys(formState.errors).forEach(key => {
      errors[key] = formState.errors[key];
      if (formState.errors[key].message !== oldErrors.current[key]?.message) {
        trackErrorCreatedCustom('', pathname, formState.errors[key].message, '', formOrigin);
      }
    });

    oldErrors.current = errors;
  }, [formState]);

  const trackField = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    track('fieldEntered', {
      value: e.target.value,
      page_id: pathname,
      name: e.target.name,
      id: formOrigin,
    });
  };

  const randomizeUsername = () => {
    if (!data?.randomizedUsernames) {
      return;
    }

    if (randomCount > data?.randomizedUsernames.length - 3) {
      fetchMore({ variables: 30 });
    }

    setValue('username', data?.randomizedUsernames[randomCount]);
    setRandomCount(randomCount + 1);
  };

  const onSubmit = async (values: CreateAccountFormData) => {
    const birthdate = new Date(values.birthdate);
    const isOverThirteen = isOverAge(birthdate);
    const email = values.email ? values.email : null;

    const payload = {
      birthdate,
      email: isOverThirteen ? email : null,
      username: values.username,
      password: values.password,
      type: UserType.Registered,
      rewardSoftCurrency,
    };

    const res = await submitGqlForm<SignUpWithEmailMutation, SignUpWithEmailMutationResult>([
      signUpWithEmail({
        payload,
      }),
    ]);

    if (res.success) {
      const authResponse = res?.data[0]?.data?.createUser;
      const user = authResponse?.user;
      const cookies = cookie.parse(document.cookie);

      if (user) {
        gtagRecordEvent({ event: 'account_created', provider: email ? 'email' : 'username' });
        trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(birthdate), user });
        trackUserSignedUp({ newUserId: user.id, loginType: 'user', thirdPartyId: '', username: user.username, user });
      }

      if (authResponse) {
        onRegisterSuccess(authResponse, 'email');
      }
    } else {
      form.setError('generic', {
        type: 'manual',
        message: 'Whoops, looks like something went wrong.',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form1" method="post" autoComplete="off" className={className}>
      <section style={{ position: 'relative' }}>
        <Label size="medium" className="mb-1 block">
          Username
        </Label>
        <TextInput
          {...register('username')}
          aria-label="username"
          color={color}
          onIconClick={randomizeUsername}
          Icon={<Shuffle size={24} title="Randomize username" />}
          onBlur={trackField}
        />
        <Label size="medium" className="mt-6 mb-1 block">
          Birthday
        </Label>
        <BirthDaySelect form={form} color={color} hideIcon={hideBirthdaySelectIcon} />
        <ErrorMessage message={formState?.errors?.birthdate?.message} className="mt-2" />
        <ErrorMessage message={formState?.errors?.month?.message} className="mt-2" />
        <ErrorMessage message={formState?.errors?.day?.message} className="mt-2" />
        <ErrorMessage message={formState?.errors?.year?.message} className="mt-2" />
        <ErrorMessage message={formState?.errors?.username?.message} className="mt-8" />
        <Label size="medium" className="mt-6 mb-1 block">
          Email
        </Label>
        <TextInput color={color} {...register('email')} aria-label="email" onBlur={trackField} />
        <ErrorMessage message={formState?.errors?.email?.message} className="mt-2" />
        <Label size="medium" className="mt-6 mb-1 block">
          Password
        </Label>
        <TextInput color={color} {...register('password')} aria-label="password" type="password" />
        <ErrorMessage message={formState?.errors?.password?.message} className="mt-2" />
      </section>

      <Button
        className="mt-6"
        variety="primary"
        size="medium"
        fullWidth
        busy={submitting || busy}
        type="submit"
        text="Sign Up"
        data-cy="createAccountButton"
      />

      {form?.formState?.errors?.generic?.message && <ErrorField error={form.formState.errors.generic.message} />}
    </form>
  );
};

export default CreateAccountForm;
