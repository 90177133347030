import { Url } from 'next/dist/shared/lib/router/router';
import NextLink, { LinkProps } from 'next/link';
import { ReactNode } from 'react';
import { twc } from 'react-twc';

const HiberLink = twc(NextLink)`
  text-link-default-text
  no-underline
  hover:text-link-hover-text
`;

const HiberLinkNoHref = twc.div`
  text-text-default
  no-underline
  cursor-default
`;

export const Link = ({
  ...props
}: LinkProps & {
  children: ReactNode;
  passHref?: boolean;
  legacyBehaviour?: boolean;
  target?: '_self' | '_blank';
  rel?: string;
  className?: string;
}) => {
  return <HiberLink {...props} />;
};

// Wraps in a normal div if no href is provided
export const OptionalLink = ({
  href,
  className,
  ...props
}: Omit<LinkProps, 'href'> & {
  href?: Url;
  children: ReactNode;
  passHref?: boolean;
  legacyBehaviour?: boolean;
  target?: '_self' | '_blank';
  rel?: string;
  className?: string;
}) => {
  if (!href) {
    return <HiberLinkNoHref className={className}>{props.children}</HiberLinkNoHref>;
  }
  return <HiberLink href={href} {...props} className={className} />;
};
