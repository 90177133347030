import { ReactNode } from 'react';
import { Button, ButtonProps } from './Button';
import { Label } from 'components/typography';

type AuthButtonProps = { Icon: ReactNode; size?: ButtonProps['size'] } & Omit<ButtonProps, 'size'>;

export const AuthButton = ({ text, Icon, variety, className, ...props }: AuthButtonProps) => {
  return (
    <Button className={'relative ' + className} size="large" variety={variety || 'secondary'} fullWidth {...props}>
      <div className="absolute left-4 h-6">{Icon}</div>
      <Label className="cursor-[inherit]" size="large">
        {text}
      </Label>
    </Button>
  );
};
