import * as S from './Tooltip.styles';
import { TooltipProps } from './Tooltip.types';

export const Tooltip = ({ text, children, xOffset, reverse = false, enabled = true }: TooltipProps) => {
  if (enabled === false) {
    return <>{children}</>;
  }

  return (
    <S.Container data-testid="tooltip-container">
      <div className="flex items-center justify-center">
        {children}
        <S.LabelWrapper $reverse={reverse} $translateX={xOffset ?? 0} data-testid="tooltip">
          <S.Label $reverse={reverse} size="medium">
            {text}
          </S.Label>
        </S.LabelWrapper>
      </div>
    </S.Container>
  );
};
