import dynamic from 'next/dynamic';
import { WalletSignInButtonProps } from 'views/global/Wallet/WalletSignInButton';

const WalletSignInButton = dynamic(() => import('views/global/Wallet/WalletSignInButton'), {
  ssr: false,
});

export const DynamicWalletSignInButton = ({
  navigateToUsername,
  onCompleted,
  onCompleteRedirectPage,
}: WalletSignInButtonProps) => (
  <WalletSignInButton
    navigateToUsername={navigateToUsername}
    onCompleted={onCompleted}
    onCompleteRedirectPage={onCompleteRedirectPage}
  />
);
