import { SignUpWithEmailMutationVariables, useSignUpWithEmailMutation } from 'generated/graphql';

export const useSignUpWithEmail = () => {
  const [signUpWithEmailMutation, signUpWithEmailData] = useSignUpWithEmailMutation();

  const signUpWithEmail = (variables: SignUpWithEmailMutationVariables) => {
    return signUpWithEmailMutation({
      variables,
    });
  };

  return { signUpWithEmail, ...signUpWithEmailData };
};
