import { DefaultAvatarInput, Gender, useCreateDefaultAvatarMutation } from 'generated/graphql';
import { useSearchParam } from 'lib/useSearchParam';
import { useCallback } from 'react';

type CreateDefaultAvatarOptions = Parameters<typeof useCreateDefaultAvatarMutation>[0];

const isValidGender = (value: unknown): value is Gender =>
  typeof value === 'string' && Object.keys(Gender).includes(value);

export const useCreateDefaultAvatar = (options?: CreateDefaultAvatarOptions) => {
  const defaultAvatar = useSearchParam('defaultAvatar') as Gender;
  const [create, result] = useCreateDefaultAvatarMutation(options);

  const createDefaultAvatar = useCallback(
    async (input: DefaultAvatarInput) => {
      const gender = input.gender ?? defaultAvatar;
      return create({
        variables: {
          input: {
            ...(isValidGender(gender) ? { gender } : {}),
            provider: input.provider,
          },
        },
      });
    },
    [create, defaultAvatar]
  );

  return [createDefaultAvatar, result] as const;
};
