import { useUnseenAchievementsLootQuery } from 'generated/graphql';
import { useMe } from 'hooks/useMe';

export const useHasUnclaimedLoot = () => {
  const { isLoggedIn } = useMe();
  const result = useUnseenAchievementsLootQuery({
    skip: !isLoggedIn,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  return (result.data?.me?.avatarV2?.claimableRpmAchievement.length ?? 0) > 0;
};
