export const SoftCurrencyLogo = ({ size = 20, className }: { size?: number; className?: string }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 980 996"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M490.044 986.669C755.311 986.669 970.353 767.664 970.353 497.509C970.353 227.353 755.311 8.34912 490.044 8.34912C224.776 8.34912 9.73389 227.353 9.73389 497.509C9.73389 767.664 224.776 986.669 490.044 986.669Z"
      fill="#82421D"
      stroke="#8E4C1E"
      strokeMiterlimit="10"
    />
    <path
      d="M489.602 946.062C748.544 946.062 958.458 736.148 958.458 477.206C958.458 218.263 748.544 8.34912 489.602 8.34912C230.659 8.34912 20.7451 218.263 20.7451 477.206C20.7451 736.148 230.659 946.062 489.602 946.062Z"
      fill="url(#paint0_linear_10047_51204)"
    />
    <path
      d="M489.091 863.347C700.539 863.347 871.952 690.747 871.952 477.834C871.952 264.922 700.539 92.3215 489.091 92.3215C277.643 92.3215 106.23 264.922 106.23 477.834C106.23 690.747 277.643 863.347 489.091 863.347Z"
      fill="url(#paint1_linear_10047_51204)"
    />
    <path
      d="M489.602 846.374C688.844 846.374 850.362 684.83 850.362 485.555C850.362 286.28 688.844 124.736 489.602 124.736C290.359 124.736 128.841 286.28 128.841 485.555C128.841 684.83 290.359 846.374 489.602 846.374Z"
      fill="url(#paint2_linear_10047_51204)"
    />
    <path
      d="M752.402 414.784C740.606 370.455 716 329.859 680.394 296.845C629.317 249.54 561.542 223.481 489.553 223.481C417.564 223.481 349.759 249.54 298.712 296.845C263.066 329.859 238.49 370.485 226.693 414.823C199.269 425.353 179.801 448.18 178.219 490.309V525.631C178.219 535.876 180.164 546.022 183.946 555.491C187.727 564.96 193.277 573.555 200.261 580.804C207.255 588.053 215.555 593.799 224.68 597.718C233.815 601.637 243.598 603.651 253.489 603.651H295.107L296.6 409.794L272.918 407.338C270.069 407.338 267.24 407.417 264.5 407.545C295.853 320.979 384.874 258.39 489.533 258.39C594.192 258.39 683.174 320.979 714.615 407.545C711.845 407.417 709.056 407.338 706.197 407.338L682.515 409.794L684.008 603.68H725.626C735.518 603.68 745.311 601.676 754.455 597.757C763.59 593.838 771.9 588.092 778.894 580.843C785.887 573.594 791.437 564.989 795.219 555.511C799 546.042 800.945 535.885 800.945 525.631V490.309C799.364 448.082 779.896 425.274 752.363 414.784H752.402Z"
      fill="#753E1A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M268.724 522.016C268.724 395.581 367.567 293.083 489.553 293.083C611.538 293.083 710.47 395.63 710.47 522.016C710.47 648.402 611.558 750.939 489.602 750.939C367.646 750.939 268.724 648.451 268.724 522.016ZM604.073 620.349C606.696 614.544 604.122 607.717 598.327 605.085C592.522 602.462 585.695 605.036 583.063 610.831C567.769 644.63 538.695 658.401 509.109 658.499C502.744 658.519 497.597 663.705 497.617 670.07C497.637 676.435 502.823 681.582 509.188 681.563C546.386 681.435 584.507 663.578 604.083 620.339L604.073 620.349Z"
      fill="#753E1A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M269.627 532.31C269.627 405.875 368.471 303.377 490.457 303.377C612.442 303.377 711.374 405.924 711.374 532.31C711.374 658.696 612.462 761.233 490.506 761.233C368.55 761.233 269.627 658.745 269.627 532.31ZM604.977 630.643C607.599 624.838 605.026 618.011 599.231 615.379C593.426 612.756 586.599 615.33 583.967 621.125C568.673 654.924 539.598 668.695 510.013 668.793C503.648 668.813 498.501 673.999 498.521 680.364C498.54 686.729 503.727 691.876 510.092 691.857C547.289 691.729 585.411 673.872 604.987 630.633L604.977 630.643Z"
      fill="url(#paint3_linear_10047_51204)"
    />
    <path
      d="M753.296 425.088C741.5 380.759 716.894 340.163 681.288 307.149C630.211 259.844 562.436 233.785 490.447 233.785C418.458 233.785 350.653 259.844 299.606 307.149C263.96 340.163 239.384 380.788 227.587 425.127C200.163 435.657 180.695 458.484 179.113 500.613V535.935C179.113 546.179 181.058 556.326 184.84 565.795C188.621 575.264 194.171 583.858 201.155 591.107C208.149 598.356 216.449 604.103 225.574 608.022C234.709 611.941 244.492 613.954 254.383 613.954H296.001L297.494 420.098L273.812 417.642C270.963 417.642 268.134 417.721 265.394 417.849C296.747 331.283 385.769 268.694 490.427 268.694C595.086 268.694 684.068 331.283 715.509 417.849C712.739 417.721 709.95 417.642 707.091 417.642L683.41 420.098L684.903 613.984H726.52C736.412 613.984 746.205 611.98 755.349 608.061C764.484 604.142 772.794 598.396 779.788 591.147C786.781 583.898 792.331 575.293 796.113 565.814C799.894 556.346 801.839 546.189 801.839 535.935V500.613C800.258 458.386 780.79 435.578 753.257 425.088H753.296Z"
      fill="url(#paint4_linear_10047_51204)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M281.955 526.829C281.955 409.519 375.278 314.428 490.457 314.428C605.635 314.428 699.047 409.568 699.047 526.829C699.047 644.09 605.655 739.221 490.506 739.221C375.357 739.221 281.955 644.129 281.955 526.829ZM598.592 618.06C601.067 612.678 598.641 606.342 593.16 603.896C587.679 601.46 581.226 603.847 578.751 609.23C564.312 640.583 536.858 653.362 508.923 653.461C502.911 653.48 498.049 658.283 498.069 664.197C498.089 670.11 502.98 674.883 508.992 674.864C544.117 674.746 580.106 658.175 598.582 618.06H598.592Z"
      fill="#AA6027"
    />
    <path
      d="M738.631 427.347C727.493 386.22 704.263 348.551 670.65 317.924C622.432 274.028 558.438 249.855 490.457 249.855C422.475 249.855 358.472 274.028 310.263 317.924C276.611 348.561 253.401 386.25 242.262 427.386C216.37 437.15 197.982 458.337 196.489 497.421V530.188C196.489 539.697 198.326 549.106 201.901 557.888C205.477 566.669 210.712 574.645 217.313 581.373C223.914 588.092 231.752 593.425 240.376 597.06C249 600.694 258.243 602.57 267.575 602.57H306.874L308.289 422.711L285.933 420.432C283.241 420.432 280.58 420.501 277.986 420.628C307.591 340.31 391.642 282.239 490.457 282.239C589.271 282.239 673.292 340.31 702.976 420.628C700.363 420.51 697.731 420.432 695.03 420.432L672.674 422.711L674.088 602.6H713.388C722.729 602.6 731.972 600.743 740.606 597.099C749.24 593.465 757.078 588.131 763.679 581.403C770.28 574.674 775.525 566.689 779.09 557.907C782.666 549.116 784.493 539.697 784.493 530.188V497.421C783 458.248 764.622 437.081 738.622 427.347H738.631Z"
      fill="#AA6027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M600.793 612.707C603.327 607.295 600.842 600.91 595.253 598.455C589.664 595.999 583.083 598.405 580.548 603.827C565.815 635.377 537.801 648.235 509.296 648.333C503.157 648.353 498.206 653.195 498.226 659.138C498.246 665.08 503.236 669.884 509.375 669.864C545.217 669.746 581.943 653.077 600.793 612.707Z"
      stroke="url(#paint5_linear_10047_51204)"
      strokeWidth="16"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M606.705 634.827C610.055 628.845 608.483 621.105 603.179 617.54C597.885 613.974 590.872 615.939 587.522 621.92C567.995 656.761 537.781 668.155 508.932 664.648C502.725 663.892 496.998 668.892 496.153 675.816C495.309 682.741 499.65 688.959 505.868 689.715C542.142 694.135 581.737 679.402 606.715 634.827H606.705Z"
      fill="#753F1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M600.793 620.879C603.327 615.467 600.842 609.083 595.253 606.627C589.664 604.171 583.083 606.578 580.548 612C565.815 643.55 537.801 656.407 509.296 656.505C503.157 656.525 498.206 661.368 498.226 667.31C498.246 673.253 503.236 678.056 509.375 678.036C545.217 677.918 581.943 661.25 600.793 620.879Z"
      fill="#EEB023"
    />
    <path
      d="M276.169 401.543C355.829 240.317 599.82 220.652 704.822 401.543"
      stroke="url(#paint6_linear_10047_51204)"
      strokeWidth="16"
      strokeMiterlimit="10"
    />
    <path
      d="M489.602 946.062C748.544 946.062 958.458 736.148 958.458 477.206C958.458 218.263 748.544 8.34912 489.602 8.34912C230.659 8.34912 20.7451 218.263 20.7451 477.206C20.7451 736.148 230.659 946.062 489.602 946.062Z"
      stroke="url(#paint7_linear_10047_51204)"
      strokeWidth="9"
      strokeMiterlimit="10"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10047_51204"
        x1="489.602"
        y1="8.34912"
        x2="489.602"
        y2="946.062"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEE540" />
        <stop offset="0.77" stopColor="#DD9A2D" />
        <stop offset="1" stopColor="#D38328" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10047_51204"
        x1="489.091"
        y1="92.3215"
        x2="489.091"
        y2="863.347"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.3" stopColor="#904D25" />
        <stop offset="0.47" stopColor="#B27028" />
        <stop offset="0.53" stopColor="#BF7E2A" />
        <stop offset="0.75" stopColor="#FBDA86" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_10047_51204"
        x1="489.602"
        y1="124.736"
        x2="489.602"
        y2="846.374"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.34" stopColor="#DBA42D" />
        <stop offset="1" stopColor="#D57E21" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_10047_51204"
        x1="490.496"
        y1="761.233"
        x2="490.496"
        y2="303.377"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.31" stopColor="#EFD26B" />
        <stop offset="0.38" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_10047_51204"
        x1="490.496"
        y1="613.974"
        x2="490.496"
        y2="233.785"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.17" stopColor="#EFD26C" />
        <stop offset="0.38" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_10047_51204"
        x1="539.805"
        y1="616.027"
        x2="566.62"
        y2="662.468"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.46" stopColor="#EFD26B" />
        <stop offset="0.47" stopColor="#EFD370" stopOpacity="0.96" />
        <stop offset="0.52" stopColor="#F3DD91" stopOpacity="0.74" />
        <stop offset="0.58" stopColor="#F6E6AE" stopOpacity="0.54" />
        <stop offset="0.64" stopColor="#F9EEC7" stopOpacity="0.37" />
        <stop offset="0.69" stopColor="#FBF4DB" stopOpacity="0.24" />
        <stop offset="0.75" stopColor="#FCF8EB" stopOpacity="0.13" />
        <stop offset="0.81" stopColor="#FEFCF6" stopOpacity="0.06" />
        <stop offset="0.88" stopColor="#FEFEFC" stopOpacity="0.01" />
        <stop offset="0.95" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_10047_51204"
        x1="490.368"
        y1="265.295"
        x2="490.368"
        y2="405.492"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.35" stopColor="#EFD26B" />
        <stop offset="0.44" stopColor="#EFD26D" stopOpacity="0.98" />
        <stop offset="0.52" stopColor="#F0D474" stopOpacity="0.94" />
        <stop offset="0.61" stopColor="#F1D880" stopOpacity="0.85" />
        <stop offset="0.69" stopColor="#F3DD91" stopOpacity="0.74" />
        <stop offset="0.77" stopColor="#F5E4A7" stopOpacity="0.59" />
        <stop offset="0.85" stopColor="#F8ECC1" stopOpacity="0.41" />
        <stop offset="0.93" stopColor="#FBF5E0" stopOpacity="0.21" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_10047_51204"
        x1="489.602"
        y1="950.483"
        x2="489.602"
        y2="3.92898"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#894A18" />
        <stop offset="1" stopColor="#FFE8B4" />
      </linearGradient>
    </defs>
  </svg>
);
